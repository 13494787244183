import { authentication } from '@feathersjs/client'
import axios from 'axios'
import feathersClient,{ feathersStorage } from '../../clients/feathers.clients'

export const login = async (email: string, password: string) => {
    return await feathersClient
        .authenticate({
            strategy: 'local',
            email,
            password,
        })
        
        .then((res) => {return res})
        .catch((err) => {
            throw err
        })
}

export const retryLogin = async () => {
    return await feathersClient
        .authenticate({
            strategy: 'jwt',
            accessToken: feathersStorage.getItem('jwt'),
        })
}
