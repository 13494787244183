import { Button, Col, Row } from "antd"
import { PlusOutlined } from "@ant-design/icons";

import { AdminMenuLayout } from "../../../shared/components"
import { WrapperCardEvents } from "./styled"
import { TableEvents } from "./TableEvents";
import { useEvents } from "../hooks";

interface Props {
  isSCP: boolean
  }

export const Events = ({isSCP}:Props) => {
  const { navigateEventCreate } = useEvents()
  return (
    <AdminMenuLayout pageDescription="Events" title="Events">
      <WrapperCardEvents>
        <Row
          gutter={[8, 8]}
          style={{
            padding: "10px",
            width: "100%",
          }}
        >
          <Col
            span={24}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              type="primary"
              shape="round"
              icon={<PlusOutlined />}
              size="large"
              onClick={navigateEventCreate}
            >
              Agregar nuevo evento
            </Button>
          </Col>
        </Row>
        <TableEvents />
      </WrapperCardEvents>
    </AdminMenuLayout>
  )
}
