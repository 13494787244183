import { Button, Col, message, Row, Spin, Typography } from "antd";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";

import { selectIsUpdateEvent } from "../../../../redux";
import { IEventSingle } from "../../../../redux/@types";
import {
  InputData,
  InputHTML,
  InputSelect,
  InputText,
  SimpleInputSelect,
} from "../../../../shared/components";
import Upload from "../../../../shared/components/ui/inputs/Upload";
import { PATH_S3_BUCKET } from "../../../../shared/utils/constants";
import { useEvents } from "../../hooks";

const { Title } = Typography;

interface Props {
  isSCP: boolean;
}

export const EventsForm = ({ isSCP }: Props) => {
  const isUpdateEvents = useSelector(selectIsUpdateEvent);

  const [isValidate, setIsValidate] = useState(false);
  const [valueSelector, setValueSelector] = useState<string | undefined>();
  useEffect(() => {
    setTimeout(() => {
      setIsValidate(!isValidate);
    }, 1500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { isLoading, onSubmitCreateOrUpdate } = useEvents();

  const {
    control,
    handleSubmit: onSubmit,
    setValue,
    watch,
  } = useFormContext<IEventSingle>();
  let cover_image_path = watch("cover_image_path");
  let featured_image_path = watch("featured_image_path");
  let pdf_programa = watch("pdf_programa");

  useEffect(() => {
    if(isSCP && pdf_programa){
      if(pdf_programa?.split("/")[0] === "static"){
        setValueSelector("pdf")
      }else{
        setValueSelector("url")
      }
      // console.log(pdf_programa?.split("/"))
    }
  }, [pdf_programa])


  const changeSelectValue = (val: string) => {
    setValueSelector(val);
  };

  return (
    <>
      {!isValidate ? (
        <Row style={{ width: "100%" }} justify="start">
          <div
            style={{
              width: "100%",
              margin: "50%",
              marginTop: 0,
              marginBottom: 0,
            }}
          >
            <Spin size="large" />
          </div>
        </Row>
      ) : (
        <Row justify="start">
          <Col
            span={12}
            style={{
              display: "flex",
              marginTop: "10px",
              padding: 5,
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              Título:
            </Title>
            <InputText
              placeHolder="Título ideal"
              name="title"
              control={control}
            />
          </Col>
          {/* <Col
            span={12}
            style={{
              display: "flex",
              marginTop: "10px",
              padding: 5,
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              Slug:
            </Title>
            <InputText placeHolder="ABC123" name="slug" control={control} />
          </Col> */}
          <Col
            span={12}
            style={{
              display: "flex",
              marginTop: "10px",
              padding: 5,
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              Fecha de inicio:
            </Title>
            <InputData
              dateFormat="YYYY-MM-DD HH:mm:ss"
              placeHolder="29/10/2022 00:00:00"
              name="start_date"
              control={control}
            />
          </Col>
          <Col
            span={12}
            style={{
              display: "flex",
              marginTop: "10px",
              padding: 5,
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              Fecha de finalización:
            </Title>
            <InputData
              dateFormat="YYYY-MM-DD HH:mm:ss"
              placeHolder="29/10/2022 00:00:00"
              name="end_date"
              control={control}
            />
          </Col>
          <Col
            span={24}
            style={{
              display: "flex",
              marginTop: "10px",
              padding: 5,
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              Cuerpo del mensaje:
            </Title>
            {isUpdateEvents ? (
              <InputHTML
                placeHolder="Es un paciente muy optimo para..."
                name="html_body"
                control={control}
                isUpdating
              />
            ) : (
              <InputHTML
                placeHolder="Es un paciente muy optimo para..."
                name="html_body"
                control={control}
              />
            )}
          </Col>
          {isUpdateEvents && isSCP && (
            <Col
            span={13}
            style={{
              display: "flex",
              marginTop: "10px",
              padding: 5,
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              ¿Cómo subir el PDF?:
            </Title>
            <SimpleInputSelect
              valueSelect={valueSelector}
              onChange={changeSelectValue}
              label={"Seleccione un tipo"}
              dataOptions={[
                { title: "URL", value: "url" },
                { title: "PDF", value: "pdf" },
              ]}
              maxWidth="200px"
            />
          </Col>
          )}
          {isUpdateEvents && isSCP && valueSelector === "pdf" && (
            <Col
              span={24}
              style={{
                display: "flex",
                marginTop: "10px",
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <Title style={{ marginBottom: "1.5px" }} level={5}>
                PDF del programa:
              </Title>
              {pdf_programa && (
                <Title style={{ marginBottom: "1.5px", fontSize: '12' }} level={5}>
                  {pdf_programa}
                </Title>
              )}
              <Upload
                filePath="pdf_programa"
                onUploadedFile={(files) => {
                  console.log({ files });
                  const file = files[0];
                  setValue("pdf_programa", file.fileKey);
                  message.success("Se ha actualizado el archivo pdf");
                }}
                placeHolder={"Cargar PDF"}
              />
            </Col>
          )}
          {isUpdateEvents && isSCP && valueSelector === "url" && (
            <Col
            span={24}
            style={{
              display: "flex",
              marginTop: "10px",
              padding: 5,
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Title style={{ marginBottom: "1.5px" }} level={5}>
            PDF del programa:
            </Title>
            <InputText
              placeHolder="http://jornadasciberseguridad.riasc.unileon.es/archivos/ejemplo_esp.pdf"
              name="pdf_programa"
              control={control}
            />
          </Col>
          )}
          {isUpdateEvents && (
            <Col
              span={12}
              style={{
                display: "flex",
                marginTop: "10px",
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <Title style={{ marginBottom: "1.5px" }} level={5}>
                Ruta de la imagen destacada:
              </Title>
              {featured_image_path ? (
                <img
                  src={`${PATH_S3_BUCKET}/${featured_image_path}`}
                  alt="featured_image_path"
                  style={{
                    marginBottom: "7px",
                    width: "100%",
                    height: "100%",
                    maxWidth: "250px",
                    maxHeight: "250px",
                  }}
                />
              ) : (
                <img
                  src={`https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/330px-No-Image-Placeholder.svg.png?20200912122019`}
                  alt="featured_image_path_not"
                  style={{
                    marginBottom: "7px",
                    width: "100%",
                    height: "100%",
                    maxWidth: "250px",
                    maxHeight: "250px",
                  }}
                />
              )}
              <Upload
                filePath="featured_image_path"
                onUploadedFile={(files) => {
                  // console.log({ files });
                  const file = files[0];
                  setValue("featured_image_path", file.fileKey);
                  message.success("Se ha actualizado la imagen");
                }}
              />
            </Col>
          )}
          {isUpdateEvents && (
            <Col
              span={12}
              style={{
                display: "flex",
                marginTop: "10px",
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <Title style={{ marginBottom: "1.5px" }} level={5}>
                Imagen de cobertura:
              </Title>
              {cover_image_path ? (
                <img
                  src={`${PATH_S3_BUCKET}/${cover_image_path}`}
                  alt="cover_image_path"
                  style={{
                    marginBottom: "7px",
                    width: "100%",
                    height: "100%",
                    maxWidth: "250px",
                    maxHeight: "250px",
                  }}
                />
              ) : (
                <img
                  src={`https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/330px-No-Image-Placeholder.svg.png?20200912122019`}
                  alt="cover_image_path_not"
                  style={{
                    marginBottom: "7px",
                    width: "100%",
                    height: "100%",
                    maxWidth: "250px",
                    maxHeight: "250px",
                  }}
                />
              )}
              <Upload
                filePath="cover_image_path"
                onUploadedFile={(files) => {
                  // console.log({ files });
                  const file = files[0];
                  setValue("cover_image_path", file.fileKey);
                  message.success("Se ha actualizado la imagen");
                }}
              />
            </Col>
          )}
          {isSCP && (
            <Col
              span={12}
              style={{
                display: "flex",
                marginTop: "10px",
                padding: 5,
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <Title style={{ marginBottom: "1.5px" }} level={5}>
                Tipo de SCP:
              </Title>
              <InputSelect
                name="scp_type"
                control={control}
                dataOptions={[
                  { title: "Recomendado", value: "recommended" },
                  { title: "SCP", value: "scp" },
                ]}
                label="Tipo de SCP"
              />
            </Col>
          )}
          <Col
            span={12}
            style={{
              display: "flex",
              marginTop: "10px",
              padding: 5,
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              Web URL:
            </Title>
            <InputText
              placeHolder="https://www.google.com/"
              name="web_url"
              control={control}
            />
          </Col>
          {isUpdateEvents && (
            <Col
              span={12}
              style={{
                display: "flex",
                marginTop: "10px",
                padding: 5,
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <Title style={{ marginBottom: "1.5px" }} level={5}>
                Estado:
              </Title>
              <InputSelect
                name="status"
                control={control}
                dataOptions={[
                  { title: "Activo", value: "active" },
                  { title: "Inactivo", value: "inactive" },
                ]}
                label="Estado"
              />
            </Col>
          )}
          <Col
            span={24}
            style={{
              display: "flex",
              marginTop: "10px",
              padding: 5,
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              Descripción corta:
            </Title>
            {isUpdateEvents ? (
              <InputHTML
                placeHolder="Es un evento que..."
                name="html_short_description"
                control={control}
                isUpdating
              />
            ) : (
              <InputHTML
                placeHolder="Es un evento que..."
                name="html_short_description"
                control={control}
              />
            )}
          </Col>
          <Col
            span={24}
            style={{
              display: "flex",
              marginTop: "10px",
              padding: 5,
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              Locación del evento:
            </Title>
            {isUpdateEvents ? (
              <InputHTML
                placeHolder="Esta ubicado en..."
                name="event_location"
                control={control}
                isUpdating
              />
            ) : (
              <InputHTML
                placeHolder="Esta ubicado en..."
                name="event_location"
                control={control}
              />
            )}
          </Col>
          <Col
            span={12}
            style={{
              display: "flex",
              marginTop: "10px",
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Button
              style={{
                width: "100%",
                marginTop: 10,
              }}
              type="primary"
              shape="round"
              size="large"
              onClick={onSubmit(onSubmitCreateOrUpdate)}
              loading={isLoading}
            >
              {isUpdateEvents ? `Actualizar información` : `Crear evento`}
            </Button>
          </Col>
        </Row>
      )}
    </>
  );
};
