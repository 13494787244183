import { Button, Col, message, Row } from "antd";
import { useState } from "react";
import { DownloadOutlined } from "@ant-design/icons";

import { AdminMenuLayout, SimpleInputNumber } from "../../../shared/components";
import { WrapperCardNinjaFormsWebhook } from "./styled";
import { TableNinjaFormsWebhook } from "./TableNinjaFormsWebhook";
import { ninjaFormsWebhookService } from "../../../shared/services";

interface Props {
    isSCP: boolean
}

export const NinjaFormsWebhook = ({isSCP}:Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [iDForm, setIDForm] = useState<undefined | number>(undefined)

  const handleDownload = async () => {
    setIsLoading(true)
    if(!iDForm){
      setIsLoading(false);
      return message.error("Debes de ingresar un valor en el campo para poder seguir con la descarga");
    }else{
      await ninjaFormsWebhookService.find({ query: { form_id: iDForm, $client: { exportData: 'true' } } }).then((res) => {
        setIsLoading(false);
        message.success("Descarga exitosa")
          window.open(res.data[0].objectUrl, '_blank')
      })
      .catch((err: any) => {
        setIsLoading(false);
        console.log(err);
        message.error(err.message);
      });

    }
}

  return (
    <AdminMenuLayout pageDescription="NinjaFormsWebhook" title="NinjaFormsWebhook">
      <WrapperCardNinjaFormsWebhook>
        <Row
          gutter={[8, 8]}
          style={{
            padding: "10px",
            width: "100%",
          }}
        >
          <Col
            span={12}
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <SimpleInputNumber
              placeHolder="Escribe aquí el ID de Formulario"
              onChange={(val) => setIDForm( val )}
              value={iDForm}
              max={999999999999}
              isDisable={false}
              />
              <Button
              type="primary"
              disabled={isLoading}
              style={{marginLeft: "9px"}}
              shape="circle"
              icon={<DownloadOutlined />}
              size="large"
              onClick={handleDownload}
            />
          </Col>
            <TableNinjaFormsWebhook isSCP={isSCP} />
        </Row>
      </WrapperCardNinjaFormsWebhook>
    </AdminMenuLayout>
  )
}
