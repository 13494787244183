import { clinicalCasesService } from "../../shared/services";
import { IClinicalCaseSingle } from "../@types";
import { clinicalCasesActions } from "../action-types";


export const clinicalCasesChangeModalState = (): clinicalCasesActions.IClinicalCaseChangeModalState => ({
    type: clinicalCasesActions.CLINICAL_CASES_CHANGE_MODAL_STATE
})

export const setClinicalCaseForUpdate = ( val: IClinicalCaseSingle ): clinicalCasesActions.IGetClinicalCaseForUpdate =>({
    type: clinicalCasesActions.GET_CLINICAL_CASES_FOR_UPDATE,
    payload: val
})

export const setIsClinicalCaseForUpdate = (val: boolean ): clinicalCasesActions.IIsClinicalCaseForUpdate => ({
    type: clinicalCasesActions.IS_CLINICAL_CASE_FOR_UPDATE,
    payload: val
})

export const createClinicalCase =async( data: Partial<IClinicalCaseSingle> ) => {
    return await clinicalCasesService.create(data)
    .then((res: IClinicalCaseSingle) => {return res})
    .catch((err: any) => { return err.message })
}

export const updateClinicalCase = async(data:Partial<IClinicalCaseSingle>) => {
    return  await clinicalCasesService.patch(data?.id!, data)
    .then((res: any) => {
        // console.log({res});
        return res
    })
    .catch((err: any) => { return err.message })
}

export const deleteClinicalCase = async (id: number ) =>{
    return  await clinicalCasesService.remove(id.toString())
    .then((res: any) => {return res})
    .catch((err: any) => { return err.message })
}

export const refreshTableClinicalCase =( val: boolean ): clinicalCasesActions.ISwitchClinicalCasesRefreshTable =>({
    type: clinicalCasesActions.SWITCH_CLINICAL_CASES_REFRESH_TABLE,
    payload: val
})