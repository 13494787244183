import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { message } from "antd";
import { useSelector } from "react-redux";

import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import {
  createEventInscription,
  eventInscriptionChangeModalState,
  refreshTableEventInscription,
  selectForUpdateEventInscription,
  selectIsUpdateEventInscription,
  selectRefreshTableEventInscription,
  setIsEventInscriptionForUpdate,
  updateEventInscription,
} from "../../../../redux";
import { TableProperties } from "../../../../shared/components/ui/Table/types";
import { IEventInscriptionSingle } from "../../../../redux/@types";
import {
  validateCreateEventInscription,
  validateUpdateEventInscription,
} from "../../../../helpers";

export const useEventsInscriptions = () => {
  const dispatch = useAppDispatch();
  const locationPath = useLocation();
  const { id: idForInscription } = useParams();
  const isUpdateEventInscription = useSelector(selectIsUpdateEventInscription);
  const dataUpdateEventInscription = useSelector(
    selectForUpdateEventInscription
  );
  const isRefreshTableEventInscriptions = useSelector(
    selectRefreshTableEventInscription
  );

  const [isLoading, setIsLoading] = useState(false);
  const [tableProps, setTableProps] = useState<TableProperties>();

  const navigateEventInscriptionCreate = () => {
    dispatch(setIsEventInscriptionForUpdate(false));
    dispatch(eventInscriptionChangeModalState());
  };

  const changeModalEventInscriptionState = () => {
    dispatch(eventInscriptionChangeModalState());
  };

  const formMethodsCreate = useForm<IEventInscriptionSingle>({
    resolver: yupResolver(validateCreateEventInscription),
  });

  const formMethodsUpdate = useForm<IEventInscriptionSingle>({
    resolver: yupResolver(validateUpdateEventInscription),
  });

  useEffect(() => {
    if (isUpdateEventInscription) {
      formMethodsCreate.reset();
      formMethodsUpdate.reset({ ...dataUpdateEventInscription });
    }

    return () => {
      formMethodsUpdate.reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateEventInscription, dataUpdateEventInscription]);

  const updateStatus = async (value: "active" | "inactive", id: any) => {
    if (isLoading) return;
    setIsLoading(true);
    await updateEventInscription({
      id,
      status: value,
    })
      .then((res: Partial<IEventInscriptionSingle>) => {
        setIsLoading(false);
        if (res.id) {
          // dispatch(bannersChangeModalState());
          message.success(
            `Se ha actualizado con éxito el estado de la inscripción con id:${res.id}`
          );
          dispatch(refreshTableEventInscription(true));
        } else {
          message.error(res as string);
        }
      })
      .catch((err: any) => {
        setIsLoading(false);
        console.log(err);
        message.error(err.message);
      });
  };

  const onSubmitCreateOrUpdate = async (data: IEventInscriptionSingle) => {
    // console.log({ data });
    if (isLoading) return;
    setIsLoading(true);
    if (isUpdateEventInscription) {
      // console.log("Actualizando===>", data);
      await updateEventInscription({
        id: data.id,
        title: data.title,
        price: data.price,
        html_body: data.html_body,
        external_url: data.external_url,
        priority: data.priority,
        status: data.status,
      })
        .then((res: Partial<IEventInscriptionSingle>) => {
          setIsLoading(false);
          // dispatch(mappingUpdatedUser(res))
          if (res.id) {
            // navigate(`/event/${locationPath.pathname.split("/")[2]}`);
            message.success(
              `Se ha actualizado con éxito la inscripción con id:${res.id}`
            );
            dispatch(refreshTableEventInscription(true));
          } else {
            message.error(res as string);
          }
        })
        .catch((err: any) => {
          setIsLoading(false);
          console.log(err);
          message.error(err.message);
        });
    } else {
      // console.log('Creando===>',data)
      // setIsLoading(false);
      await createEventInscription({
        event_id: idForInscription,
        title: data.title,
        price: data.price,
        html_body: data.html_body,
        external_url: data.external_url,
        priority: data.priority,
      })
        .then((res: any) => {
          setIsLoading(false);
          if (res.id) {
            dispatch(eventInscriptionChangeModalState());
            // navigate(`/event/${locationPath.pathname.split("/")[2]}`);
            message.success(
              `Se ha creado con éxito la inscripción con id:${res.id}`
            );
            dispatch(refreshTableEventInscription(true));
          } else {
            message.error(res as any);
          }
        })
        .catch((err: any) => {
          setIsLoading(false);
          console.log(err);
          message.error(err.message);
        });
    }
  };

  useEffect(() => {
    if (isRefreshTableEventInscriptions) {
      tableProps?.refresh();
      dispatch(refreshTableEventInscription(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefreshTableEventInscriptions]);

  return {
    //state
    isUpdateEventInscription,
    isLoading,
    tableProps,
    locationPath,
    idForInscription,
    //methods
    formMethodsCreate,
    formMethodsUpdate,
    setTableProps,
    //functions
    navigateEventInscriptionCreate,
    updateStatus,
    onSubmitCreateOrUpdate,
    changeModalEventInscriptionState,
  };
};
