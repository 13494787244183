import { Avatar, Button, Col, Typography } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

import Table from "../../../shared/components/ui/Table";
import { TableColumns } from "../../../shared/components/ui/Table/types";
import { useClinicalCases } from "../hooks";
import { clinicalCasesService } from "../../../shared/services";
import { IClinicalCaseSingle } from "../../../redux/@types";
import { useTableClinicalCases } from '../hooks/useTableClinicalCases';
import { PATH_S3_BUCKET } from "../../../shared/utils/constants";

const { Title } = Typography;

export const TableClinicalCases = () => {
    const { setTableProps, updateStatus, isLoading, locationPath } = useClinicalCases();
    const { changeProductsForUpdate, showDeleteConfirm } = useTableClinicalCases()
    const columns: TableColumns<IClinicalCaseSingle> = [
        {
          title: "ID",
          width: 5,
          dataIndex: "id",
          key: "id",
          filterType: "text",
        },
        {
          title: "Imagen de cobertura",
          width: 7,
          dataIndex: "cover_image_path",
          key: "cover_image_path",
          render: (_, item) => {
            return (
              <Col
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-evenly",
                  flexDirection: "row",
                }}
              >
                {item.cover_image_path && (
                  <Avatar
                    size={64}
                    src={`${PATH_S3_BUCKET}/${item.cover_image_path}`}
                  />
                )}
              </Col>
            );
          },
        },
        {
          title: "Ruta de la imagen destacada",
          width: 7,
          dataIndex: "featured_image_path",
          key: "featured_image_path",
          render: (_, item) => {
            return (
              <Col
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-evenly",
                  flexDirection: "row",
                }}
              >
                {item.featured_image_path && (
                  <Avatar
                    size={64}
                    src={`${PATH_S3_BUCKET}/${item.featured_image_path}`}
                  />
                )}
              </Col>
            );
          },
        },
        {
            title: "Título",
            width: 12,
            dataIndex: "title",
            key: "title",
            filterType: "text",
        },
        {
            title: "Clasificación de edades",
            width: 12,
            dataIndex: "age_classification",
            key: "age_classification",
        },
        // {
        //     title: "SLUG",
        //     width: 5,
        //     dataIndex: "slug",
        //     key: "slug",
        // },
        // {
        //     title: "Natural y venidero/a",
        //     width: 5,
        //     dataIndex: "natural_and_coming",
        //     key: "natural_and_coming",
        // },
        {
            title: "Género",
            width: 5,
            dataIndex: "gender",
            key: "gender",
            filters: [
                {
                  text: "Masculino",
                  value: "M",
                },
                {
                  text: "Femenino",
                  value: "F",
                },
              ],
        },
        {
          title: "Nombre",
          width: 9,
          dataIndex: "name",
          key: "name",
        },
        {
            title: "Razón de las consultas",
            width: 12,
            dataIndex: "reason_for_consultation",
            key: "reason_for_consultation",
          },
        {
          title: "Estado",
          width: 8,
          dataIndex: "status",
          key: "status",
          render: (_, item) => {
            return (
              <Col
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-evenly",
                  flexDirection: "row",
                }}
              >
                <button
                disabled={isLoading}
                  onClick={()=>{
                    if(item.status === "active"){
                      updateStatus("inactive", item.id)
                    }else if( item.status === "inactive"){
                      updateStatus("active", item.id)
                    }
                  }}
                  style={{
                    display: "flex",
                    padding: "5px 27px",
                    borderWidth: 0,
                    cursor: "pointer",
                    borderRadius: "20px",
                    backgroundColor:
                      item.status === "active" ? "#4bd542" : "#ff4d4f",
                  }}
                >
                  <Title
                    style={{
                      marginBottom: "1.5px",
                      color: "white",
                    }}
                    level={5}
                  >
                    {item.status}
                  </Title>
                </button>
              </Col>
            );
          },
          filters: [
            {
              text: "Activo",
              value: "active",
            },
            {
              text: "Inactivo",
              value: "inactive",
            },
          ],
        },
        {
          title: "Acciones",
          key: "operation",
          fixed: "right",
          width: 7,
          render: (_, item) => {
            return (
              <Col
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-evenly",
                  flexDirection: "row",
                }}
              >
                <Button
                  type="primary"
                  shape="circle"
                  onClick={() => changeProductsForUpdate(item)}
                  icon={<EditOutlined />}
                  size="large"
                />
                <Button
                  type="primary"
                  danger
                  onClick={() => showDeleteConfirm(item.id, `${item.name}`)}
                  shape="circle"
                  icon={<DeleteOutlined />}
                  size="large"
                />
              </Col>
            );
          },
        },
      ];
  return (
        <Table<IClinicalCaseSingle>
            columns={columns}
            fetchQueryProps={{
                $sort: { id: -1 },
                client_page: locationPath.pathname.split("/")[2]
            }}
            service={clinicalCasesService}
            onLoad={setTableProps}
            />
      )
}
