import { useSelector } from "react-redux";
import { Button, Col, message, Typography } from "antd";
import { useFormContext } from "react-hook-form";

import { InputNumber, InputText } from "../inputs";
import { WrapperModalEvents } from "../../../../modules/events/ui/styled";
import {
  selectForUpdateEventSpeakerForUpdate,
  selectIsUpdateEventSpeaker,
  selectModalEventSpeaker,
} from "../../../../redux";
import { useEventsSpeakers } from "../../../../modules";
import { IEventSpeakerSingle } from "../../../../redux/@types";
import { PATH_S3_BUCKET } from "../../../utils/constants";
import Upload from "../inputs/Upload";

const { Title } = Typography;

export const ModalEventSpeaker = () => {
  const modalEventSpeaker = useSelector(selectModalEventSpeaker);
  const isUpdateEventSpeaker = useSelector(selectIsUpdateEventSpeaker);
  const updateEventSpeaker = useSelector(selectForUpdateEventSpeakerForUpdate);
  const { isLoading, changeModalEventSpeakerState, onSubmitCreateOrUpdate } =
    useEventsSpeakers();

  const {
    control,
    handleSubmit: onSubmit,
    watch,
    setValue,
  } = useFormContext<IEventSpeakerSingle>();
  let image_path = watch("image_path");
  return (
    <WrapperModalEvents
      title={
        isUpdateEventSpeaker
          ? `Actualizando conferencista: '${updateEventSpeaker?.id!}'`
          : `Creando conferencista`
      }
      open={modalEventSpeaker}
      width={"320px"}
      destroyOnClose
      onClose={changeModalEventSpeakerState}
    >
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Nombre:
        </Title>
        <InputText placeHolder="John Doe" name="name" control={control} />
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Cargo:
        </Title>
        <InputText placeHolder="Medico" name="cargo" control={control} />
      </Col>
      {isUpdateEventSpeaker && (
        <Col
          span={24}
          style={{
            display: "flex",
            marginTop: "10px",
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <Title style={{ marginBottom: "1.5px" }} level={5}>
            Ruta de la imagen destacada:
          </Title>
          {image_path ? (
            <img
              src={`${PATH_S3_BUCKET}/${image_path}`}
              alt="image_path"
              style={{
                marginBottom: "7px",
                width: "100%",
                height: "100%",
                maxWidth: "250px",
                maxHeight: "250px",
              }}
            />
          ) : (
            <img
              src={`https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/330px-No-Image-Placeholder.svg.png?20200912122019`}
              alt="image_path_not"
              style={{
                marginBottom: "7px",
                width: "100%",
                height: "100%",
                maxWidth: "250px",
                maxHeight: "250px",
              }}
            />
          )}
          <Upload
            filePath="image_path"
            onUploadedFile={(files) => {
              // console.log({ files });
              const file = files[0];
              setValue("image_path", file.fileKey);
              message.success("Se ha actualizado la imagen");
            }}
          />
        </Col>
      )}
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Prioridad:
        </Title>
        <InputNumber
          //   stringMode={true}
          placeHolder="8"
          name="priority"
          control={control}
          min
        />
      </Col>
      <Button
        style={{
          width: "100%",
          marginTop: 10,
        }}
        type="primary"
        shape="round"
        size="large"
        onClick={onSubmit(onSubmitCreateOrUpdate)}
        loading={isLoading}
      >
        {isUpdateEventSpeaker
          ? "Actualizar información"
          : "Crear conferencista"}
      </Button>
    </WrapperModalEvents>
  );
};
