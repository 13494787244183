import { message, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";

import useAppDispatch from "../../../redux/hooks/useAppDispatch";

import {
  setIsEventForUpdate,
  deleteEvent,
  refreshTableEvent,
} from "../../../redux/actions/events.actions";
import { useEvents } from "./useEvents";

const { confirm } = Modal;

export const useTableEvents = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const locationPath = useLocation();
  const { tableProps } = useEvents();

  const changeEventForUpdate = (item: any) => {
    dispatch(setIsEventForUpdate(true));
    navigate(`/events/${locationPath.pathname.split("/")[2]}/${item.id}`);
  };

  const showDeleteConfirm = (valId: number, val: string) => {
    confirm({
      title: `Estás seguro de borrar el evento con el id: ${valId}`,
      icon: <ExclamationCircleOutlined />,
      content: `Una vez ejecutada esta acción no hay vuelta atrás, estás por borrar a "${val}", si deseas continuar dale click a "Borrar evento" en caso tal no quieras, solo oprime en "Cancelar".`,
      okText: "Borrar evento",
      okType: "danger",
      cancelText: "Cancelar",
      keyboard: true,
      maskClosable: true,
      async onOk() {
        await deleteEvent(valId)
          .then(() => {
            dispatch(refreshTableEvent(true));
            message.success(`Se ha borrado el evento con id:${valId}`);
            tableProps?.refresh();
          })
          .catch((err) => {
            console.log({ err });
            message.error(err.message);
          });
      },
      onCancel() {},
    });
  };

  return {
    //state
    //methods
    //functions
    changeEventForUpdate,
    showDeleteConfirm,
  };
};
