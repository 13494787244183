import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { message } from "antd";
import { useSelector } from "react-redux";

import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import {
  createEventSponsor,
  eventSponsorChangeModalState,
  refreshTableEventSponsor,
  selectForUpdateEventSponsor,
  selectIsUpdateEventSponsor,
  selectRefreshTableEventSponsor,
  setIsEventSponsorForUpdate,
  updateEventSponsor,
} from "../../../../redux";
import { TableProperties } from "../../../../shared/components/ui/Table/types";
import { IEventSponsorSingle } from "../../../../redux/@types";
import {
  validateCreateEventSpeaker,
  validateUpdateEventSpeaker,
} from "../../../../helpers";

export const useEventsSponsors = () => {
  const dispatch = useAppDispatch();
  const locationPath = useLocation();
  const { id: idForSponsor } = useParams();
  const isUpdateEventSponsor = useSelector(selectIsUpdateEventSponsor);
  const dataUpdateEventSponsor = useSelector(selectForUpdateEventSponsor);
  const isRefreshTableEventSponsors = useSelector(
    selectRefreshTableEventSponsor
  );

  const [isLoading, setIsLoading] = useState(false);
  const [tableProps, setTableProps] = useState<TableProperties>();

  const navigateEventSponsorCreate = () => {
    dispatch(setIsEventSponsorForUpdate(false));
    dispatch(eventSponsorChangeModalState());
  };

  const changeModalEventSponsorState = () => {
    dispatch(eventSponsorChangeModalState());
  };

  const formMethodsCreate = useForm<IEventSponsorSingle>({
    resolver: yupResolver(validateCreateEventSpeaker),
  });

  const formMethodsUpdate = useForm<IEventSponsorSingle>({
    resolver: yupResolver(validateUpdateEventSpeaker),
  });

  useEffect(() => {
    if (isUpdateEventSponsor) {
      formMethodsCreate.reset();
      formMethodsUpdate.reset({ ...dataUpdateEventSponsor });
    }

    return () => {
      formMethodsUpdate.reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateEventSponsor, dataUpdateEventSponsor]);

  const onSubmitCreateOrUpdate = async (data: IEventSponsorSingle) => {
    // console.log({ data });
    if (isLoading) return;
    setIsLoading(true);
    if (isUpdateEventSponsor) {
      // console.log("Actualiando===>", data);
      await updateEventSponsor({
        id: data.id,
        name: data.name,
        image_path: data.image_path,
        // event_id: idForSponsor,
        priority: data.priority,
      })
        .then((res: Partial<IEventSponsorSingle>) => {
          setIsLoading(false);
          // dispatch(mappingUpdatedUser(res))
          if (res.id) {
            // navigate(`/event/${locationPath.pathname.split("/")[2]}`);
            message.success(
              `Se ha actualizado con éxito el sponsor con id:${res.id}`
            );
            dispatch(refreshTableEventSponsor(true));
          } else {
            message.error(res as string);
          }
        })
        .catch((err: any) => {
          setIsLoading(false);
          console.log(err);
          message.error(err.message);
        });
    } else {
      // console.log('Creando===>',data)
      // setIsLoading(false);
      await createEventSponsor({
        name: data.name,
        image_path: data.image_path,
        event_id: idForSponsor,
        priority: data.priority,
      })
        .then((res: any) => {
          setIsLoading(false);
          if (res.id) {
            dispatch(eventSponsorChangeModalState());
            // navigate(`/event/${locationPath.pathname.split("/")[2]}`);
            message.success(
              `Se ha creado con éxito el sponsor con id:${res.id}`
            );
            dispatch(refreshTableEventSponsor(true));
          } else {
            message.error(res as any);
          }
        })
        .catch((err: any) => {
          setIsLoading(false);
          console.log(err);
          message.error(err.message);
        });
    }
  };

  useEffect(() => {
    if (isRefreshTableEventSponsors) {
      tableProps?.refresh();
      dispatch(refreshTableEventSponsor(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefreshTableEventSponsors]);

  return {
    //state
    isUpdateEventSponsor,
    isLoading,
    tableProps,
    locationPath,
    idForSponsor,
    //methods
    formMethodsCreate,
    formMethodsUpdate,
    setTableProps,
    //functions
    navigateEventSponsorCreate,
    onSubmitCreateOrUpdate,
    changeModalEventSponsorState,
  };
};
