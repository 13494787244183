import { Button, Col, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";

import { useEventsSponsors } from "../../hooks";
import { TableEventsSponsors } from "./TableEventsSponsors";
import { ModalEventSponsor } from "../../../../shared/components";
import { FormProvider } from "react-hook-form";
import { selectModalEventSponsor } from "../../../../redux";

export const EventsSponsors = () => {
  const modalEventSponsor = useSelector(selectModalEventSponsor);
  const {
    navigateEventSponsorCreate,
    isUpdateEventSponsor,
    formMethodsCreate,
    formMethodsUpdate,
  } = useEventsSponsors();
  return (
    <>
      <Row
        gutter={[8, 8]}
        style={{
          padding: "10px",
          width: "100%",
        }}
      >
        <Col
          span={24}
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            type="primary"
            shape="round"
            icon={<PlusOutlined />}
            size="large"
            onClick={navigateEventSponsorCreate}
          >
            Agregar nuevo sponsor
          </Button>
        </Col>
      </Row>
      <TableEventsSponsors />
      {modalEventSponsor && isUpdateEventSponsor && (
        <FormProvider {...formMethodsUpdate}>
          <ModalEventSponsor />
        </FormProvider>
      )}
      {modalEventSponsor && !isUpdateEventSponsor && (
        <FormProvider {...formMethodsCreate}>
          <ModalEventSponsor />
        </FormProvider>
      )}
    </>
  );
};
