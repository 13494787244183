import { Button, Col, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";

import { useEventsSpeakers } from "../../hooks";
import { TableEventsSpeakers } from "./TableEventsSpeakers";
import { ModalEventSpeaker } from "../../../../shared/components";
import { FormProvider } from "react-hook-form";
import { selectModalEventSpeaker } from "../../../../redux";

export const EventsSpeakers = () => {
  const modalEventSpeaker = useSelector(selectModalEventSpeaker);
  const {
    navigateEventSpeakerCreate,
    isUpdateEventSpeaker,
    formMethodsCreate,
    formMethodsUpdate,
  } = useEventsSpeakers();
  return (
    <>
      <Row
        gutter={[8, 8]}
        style={{
          padding: "10px",
          width: "100%",
        }}
      >
        <Col
          span={24}
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            type="primary"
            shape="round"
            icon={<PlusOutlined />}
            size="large"
            onClick={navigateEventSpeakerCreate}
          >
            Agregar nuevo conferencista
          </Button>
        </Col>
      </Row>
      <TableEventsSpeakers />
      {modalEventSpeaker && isUpdateEventSpeaker && (
        <FormProvider {...formMethodsUpdate}>
          <ModalEventSpeaker />
        </FormProvider>
      )}
      {modalEventSpeaker && !isUpdateEventSpeaker && (
        <FormProvider {...formMethodsCreate}>
          <ModalEventSpeaker />
        </FormProvider>
      )}
    </>
  );
};
