import { eventsActions } from "../action-types";
import { IEventSingle, IEventSpeakerSingle, IEventScheduleSingle, IEventScheduleTopicSingle, IEventSponsorSingle, IEventInscriptionSingle, IEventsState } from '../@types/events';

type eventsActionType =
| {type: typeof eventsActions.EVENT_SPEAKER_CHANGE_MODAL_STATE}
| {type: typeof eventsActions.EVENT_SCHEDULE_CHANGE_MODAL_STATE}
| {type: typeof eventsActions.EVENT_SCHEDULE_TOPIC_CHANGE_MODAL_STATE}
| {type: typeof eventsActions.EVENT_SPONSOR_CHANGE_MODAL_STATE}
| {type: typeof eventsActions.EVENT_INSCRIPTION_CHANGE_MODAL_STATE}
| {type: typeof eventsActions.GET_EVENT_FOR_UPDATE; payload: IEventSingle}
| {type: typeof eventsActions.GET_EVENT_SPEAKER_FOR_UPDATE; payload: IEventSpeakerSingle}
| {type: typeof eventsActions.GET_EVENT_SCHEDULE_FOR_UPDATE; payload: IEventScheduleSingle}
| {type: typeof eventsActions.GET_EVENT_SCHEDULE_TOPIC_FOR_UPDATE; payload: IEventScheduleTopicSingle}
| {type: typeof eventsActions.GET_EVENT_SPONSOR_FOR_UPDATE; payload: IEventSponsorSingle}
| {type: typeof eventsActions.GET_EVENT_INSCRIPTION_FOR_UPDATE; payload: IEventInscriptionSingle}
| {type: typeof eventsActions.IS_EVENT_FOR_UPDATE; payload: boolean}
| {type: typeof eventsActions.IS_EVENT_SPEAKER_FOR_UPDATE; payload: boolean}
| {type: typeof eventsActions.IS_EVENT_SCHEDULE_FOR_UPDATE; payload: boolean}
| {type: typeof eventsActions.IS_EVENT_SCHEDULE_TOPIC_FOR_UPDATE; payload: boolean}
| {type: typeof eventsActions.IS_EVENT_SPONSOR_FOR_UPDATE; payload: boolean}
| {type: typeof eventsActions.IS_EVENT_INSCRIPTION_FOR_UPDATE; payload: boolean}
| { type: typeof eventsActions.SWITCH_EVENT_REFRESH_TABLE; payload: boolean}
| { type: typeof eventsActions.SWITCH_EVENT_SPEAKER_REFRESH_TABLE; payload: boolean}
| { type: typeof eventsActions.SWITCH_EVENT_SCHEDULE_REFRESH_TABLE; payload: boolean}
| { type: typeof eventsActions.SWITCH_EVENT_SCHEDULE_TOPIC_REFRESH_TABLE; payload: boolean}
| { type: typeof eventsActions.SWITCH_EVENT_SPONSOR_REFRESH_TABLE; payload: boolean}
| { type: typeof eventsActions.SWITCH_EVENT_INSCRIPTION_REFRESH_TABLE; payload: boolean}


const INITIAL_STATE: IEventsState = {
    //events
    eventsForUpdate: undefined,
    isUpdateEvents: false,
    refreshTableEvents: false,
    //events-speakers
    eventSpeakerForUpdate: undefined,
    isUpdateEventSpeakers: false,
    eventSpeakersModalIsOpen: false,
    refreshTableEventSpeakers: false,
    //events-schedule
    eventScheduleForUpdate: undefined,
    isUpdateEventSchedules: false,
    eventSchedulesModalIsOpen: false,
    refreshTableEventSchedules: false,
    //events-schedule-topics
    eventScheduleTopicForUpdate: undefined,
    isUpdateEventScheduleTopics: false,
    eventScheduleTopicsModalIsOpen: false,
    refreshTableEventScheduleTopics: false,
    //events-sponsors
    eventSponsorForUpdate: undefined,
    isUpdateEventSponsors: false,
    eventSponsorsModalIsOpen: false,
    refreshTableEventSponsors: false,
    //events-inscriptions
    eventInscriptionForUpdate: undefined,
    isUpdateEventInscriptions: false,
    eventInscriptionsModalIsOpen: false,
    refreshTableEventInscriptions: false,
}

export default function eventsReducer(state: IEventsState = INITIAL_STATE, action: eventsActionType ): IEventsState{
    switch(action.type) {
        //modals
        case eventsActions.EVENT_SPEAKER_CHANGE_MODAL_STATE:
            return{
                ...state,
                eventSpeakersModalIsOpen: !state.eventSpeakersModalIsOpen,
            }
        case eventsActions.EVENT_SCHEDULE_CHANGE_MODAL_STATE:
        return{
            ...state,
            eventSchedulesModalIsOpen: !state.eventSchedulesModalIsOpen,
        }
        case eventsActions.EVENT_SCHEDULE_TOPIC_CHANGE_MODAL_STATE:
        return{
            ...state,
            eventScheduleTopicsModalIsOpen: !state.eventScheduleTopicsModalIsOpen,
        }
        case eventsActions.EVENT_SPONSOR_CHANGE_MODAL_STATE:
        return{
            ...state,
            eventSponsorsModalIsOpen: !state.eventSponsorsModalIsOpen,
        }
        case eventsActions.EVENT_INSCRIPTION_CHANGE_MODAL_STATE:
        return{
            ...state,
            eventInscriptionsModalIsOpen: !state.eventInscriptionsModalIsOpen,
        }
        //get single data
        case eventsActions.GET_EVENT_FOR_UPDATE:
            return{
                ...state,
                eventsForUpdate: action.payload,
            }
        case eventsActions.GET_EVENT_SPEAKER_FOR_UPDATE:
            return{
                ...state,
                eventSpeakerForUpdate: action.payload,
            }
        case eventsActions.GET_EVENT_SCHEDULE_FOR_UPDATE:
            return{
                ...state,
                eventScheduleForUpdate: action.payload,
            }
        case eventsActions.GET_EVENT_SCHEDULE_TOPIC_FOR_UPDATE:
            return{
                ...state,
                eventScheduleTopicForUpdate: action.payload,
            }
        case eventsActions.GET_EVENT_SPONSOR_FOR_UPDATE:
            return{
                ...state,
                eventSponsorForUpdate: action.payload,
            }
        case eventsActions.GET_EVENT_INSCRIPTION_FOR_UPDATE:
            return{
                ...state,
                eventInscriptionForUpdate: action.payload,
            }
        // is update ?
        case eventsActions.IS_EVENT_FOR_UPDATE:
            return{
                ...state,
                isUpdateEvents: action.payload,
            }
        case eventsActions.IS_EVENT_SPEAKER_FOR_UPDATE:
            return{
                ...state,
                isUpdateEventSpeakers: action.payload,
            }
        case eventsActions.IS_EVENT_SCHEDULE_FOR_UPDATE:
            return{
                ...state,
                isUpdateEventSchedules: action.payload,
            }
        case eventsActions.IS_EVENT_SCHEDULE_TOPIC_FOR_UPDATE:
            return{
                ...state,
                isUpdateEventScheduleTopics: action.payload,
            }
        case eventsActions.IS_EVENT_SPONSOR_FOR_UPDATE:
            return{
                ...state,
                isUpdateEventSponsors: action.payload,
            }
        case eventsActions.IS_EVENT_INSCRIPTION_FOR_UPDATE:
            return{
                ...state,
                isUpdateEventInscriptions: action.payload,
            }
        //change switch of table
        case eventsActions.SWITCH_EVENT_REFRESH_TABLE:
            return{
                ...state,
                refreshTableEvents: action.payload,
            }
        case eventsActions.SWITCH_EVENT_SPEAKER_REFRESH_TABLE:
            return{
                ...state,
                refreshTableEventSpeakers: action.payload,
            }
        case eventsActions.SWITCH_EVENT_SCHEDULE_REFRESH_TABLE:
            return{
                ...state,
                refreshTableEventSchedules: action.payload,
            }
        case eventsActions.SWITCH_EVENT_SCHEDULE_TOPIC_REFRESH_TABLE:
            return{
                ...state,
                refreshTableEventScheduleTopics: action.payload,
            }
        case eventsActions.SWITCH_EVENT_SPONSOR_REFRESH_TABLE:
            return{
                ...state,
                refreshTableEventSponsors: action.payload,
            }
        case eventsActions.SWITCH_EVENT_INSCRIPTION_REFRESH_TABLE:
            return{
                ...state,
                refreshTableEventInscriptions: action.payload,
            }
        default:
            return state
    }
}