import { Drawer, Modal } from "antd";
import styled from "styled-components";

//ModalUsers

export const WrapperModalUsers = styled(Drawer)`
  /* min-height: 100vh;
  padding: 0;
  display: flex;
  width: 100vw;
  position: absolute;
  top: 0;
  right: 0;
  width: 25vw;

  & .ant-modal-content {
    width: 100%;
  }

  & .ant-modal-body {
  }

  & .ant-modal-footer {
    display: none;
  }

  @media (max-width: 767px) {
    margin: 0;
  } */
`;

export const WrapperModal = styled(Modal)`
  /* min-height: 100vh;
  padding: 0;
  display: flex;
  width: 100vw;
  position: absolute;
  top: 0;
  right: 0;
  width: 25vw; */

  & .ant-modal-content {
    width: 100%;
  }

  & .ant-modal-body {
  }

  & .ant-modal-footer {
    display: none;
  }
`;
