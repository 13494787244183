import { Col, Typography } from "antd";

import Table from "../../../shared/components/ui/Table";
import { TableColumns } from "../../../shared/components/ui/Table/types";
import { IRequestsOfDataUpdateSingle } from '../../../redux/@types/requestsOfDataUpdate';
import { requestsOfDataUpdateService } from "../../../shared/services";

const { Title } = Typography;

export const TableRequestsOfDataUpdate = () => {
    const columns: TableColumns<IRequestsOfDataUpdateSingle> = [
        {
          title: "ID",
          width: 5,
          dataIndex: "id",
          key: "id",
          filterType: "text",
        },
        {
            title: "Género",
            width: 6,
            dataIndex: "gender",
            key: "gender",
            filters: [
                {
                  text: "Masculino",
                  value: "M",
                },
                {
                  text: "Femenino",
                  value: "F",
                },
              ],
        },
        {
            title: "Nombre",
            width: 9,
            dataIndex: "name",
            key: "name",
            filterType: "text",
        },
        {
            title: "Apellido",
            width: 9,
            dataIndex: "last_name",
            key: "last_name",
            filterType: "text",
        },
        {
            title: "Cédula",
            width: 9,
            dataIndex: "dni",
            key: "dni",
        },
        {
            title: "Profesión",
            width: 9,
            dataIndex: "profession",
            key: "profession",
        },
        {
            title: "Email",
            width: 9,
            dataIndex: "email",
            key: "email",
        },
        {
            title: "Teléfono",
            width: 9,
            dataIndex: "phone",
            key: "phone",
        },
        {
            title: "Dirección",
            width: 12,
            dataIndex: "address",
            key: "address",
        },
        {
            title: "Ciudad",
            width: 9,
            dataIndex: "city",
            key: "city",
        },
        {
            title: "Aceptación de términos",
            width: 8,
            dataIndex: "accepted_terms",
            key: "accepted_terms",
            render: (_, item) => {
              return (
                <Col
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-evenly",
                    flexDirection: "row",
                  }}
                >
                  <Col
                    style={{
                      display: "flex",
                      padding: "5px 27px",
                      borderWidth: 0,
                      cursor: "pointer",
                      borderRadius: "20px",
                      backgroundColor:
                        item.accepted_terms ? "#4bd542" : "#ff4d4f",
                    }}
                  >
                    <Title
                      style={{
                        marginBottom: "1.5px",
                        color: "white",
                      }}
                      level={5}
                    >
                      {item.accepted_terms ? "Aceptado" : "Denegado"}
                    </Title>
                  </Col>
                </Col>
              );
            },
            filters: [
              {
                text: "Aceptado",
                value: true,
              },
              {
                text: "Denegado",
                value: false,
              },
            ],
          },
        {
            title: "ID objetivo",
            width: 5,
            dataIndex: "target_id",
            key: "target_id",
        },
      ];

  return (
    <Table<IRequestsOfDataUpdateSingle>
        columns={columns}
        fetchQueryProps={{
            $sort: { id: -1 },
        }}
        service={requestsOfDataUpdateService}
        // onLoad={setTableProps}
        />
    )
}
