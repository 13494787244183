import { Col, Typography } from "antd";

import Table from "../../../shared/components/ui/Table";
import { TableColumns } from "../../../shared/components/ui/Table/types";
import { leadsService } from "../../../shared/services";
import { ILeadSingle } from "../../../redux/@types";

const { Title } = Typography;

export const TableLeads = () => {
    const columns: TableColumns<ILeadSingle> = [
        {
          title: "ID",
          width: 5,
          dataIndex: "id",
          key: "id",
          filterType: "text",
        },
        {
            title: "Nombre",
            width: 9,
            dataIndex: "name",
            key: "name",
            filterType: "text",
        },
        {
            title: "Email",
            width: 9,
            dataIndex: "email",
            key: "email",
            filterType: "text",
        },
        {
            title: "Sujeto",
            width: 12,
            dataIndex: "subject",
            key: "subject",
        },
        {
            title: "Mensaje",
            width: 12,
            dataIndex: "message",
            key: "message",
        },
        {
            title: "Tipo de objetivo",
            width: 8,
            dataIndex: "target_type",
            key: "target_type",
            render: (_, item) => {
              return (
                <Col
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-evenly",
                    flexDirection: "row",
                  }}
                >
                  <Col
                    style={{
                      display: "flex",
                      padding: "5px 27px",
                      borderWidth: 0,
                      cursor: "pointer",
                      borderRadius: "20px",
                      backgroundColor:
                        item.target_type === "lead" ? "#4bd542" : "#ff4d4f",
                    }}
                  >
                    <Title
                      style={{
                        marginBottom: "1.5px",
                        color: "white",
                      }}
                      level={5}
                    >
                      {item.target_type}
                    </Title>
                  </Col>
                </Col>
              );
            },
            filters: [
              {
                text: "Lead",
                value: "lead",
              },
              {
                text: "Eventos",
                value: "events",
              },
            ],
          },
        {
            title: "ID objetivo",
            width: 5,
            dataIndex: "target_id",
            key: "target_id",
        },
      ];
  return (
        <Table<ILeadSingle>
            columns={columns}
            fetchQueryProps={{
                $sort: { id: -1 },
            }}
            service={leadsService}
            // onLoad={setTableProps}
            />
      )
}
