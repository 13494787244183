import { IEventSingle, IEventSpeakerSingle, IEventScheduleSingle, IEventScheduleTopicSingle, IEventSponsorSingle, IEventInscriptionSingle } from '../@types/events';

//Modals

export const  EVENT_SPEAKER_CHANGE_MODAL_STATE = '[ EVENTS ] Event Speaker Change Modal State'

export interface IEventSpeakerChangeModalState {
    type: typeof EVENT_SPEAKER_CHANGE_MODAL_STATE
}

export const  EVENT_SCHEDULE_CHANGE_MODAL_STATE = '[ EVENTS ] Event Schedule Change Modal State'

export interface IEventScheduleChangeModalState {
    type: typeof EVENT_SCHEDULE_CHANGE_MODAL_STATE
}

export const  EVENT_SCHEDULE_TOPIC_CHANGE_MODAL_STATE = '[ EVENTS ] Event Schedule Topic Change Modal State'

export interface IEventScheduleTopicChangeModalState {
    type: typeof EVENT_SCHEDULE_TOPIC_CHANGE_MODAL_STATE
}

export const  EVENT_SPONSOR_CHANGE_MODAL_STATE = '[ EVENTS ] Event Sponsor Change Modal State'

export interface IEventSponsorChangeModalState {
    type: typeof EVENT_SPONSOR_CHANGE_MODAL_STATE
}

export const  EVENT_INSCRIPTION_CHANGE_MODAL_STATE = '[ EVENTS ] Event Inscription Change Modal State'

export interface IEventInscriptionChangeModalState {
    type: typeof EVENT_INSCRIPTION_CHANGE_MODAL_STATE
}

//get single data for update

export const GET_EVENT_FOR_UPDATE = '[ EVENTS ] Get Event For Update'

export interface IGetEventForUpdate {
    type: typeof GET_EVENT_FOR_UPDATE
    payload: IEventSingle
}

export const GET_EVENT_SPEAKER_FOR_UPDATE = '[ EVENTS ] Get Event Speaker For Update'

export interface IGetEventSpeakerForUpdate {
    type: typeof GET_EVENT_SPEAKER_FOR_UPDATE
    payload: IEventSpeakerSingle
}

export const GET_EVENT_SCHEDULE_FOR_UPDATE = '[ EVENTS ] Get Event Schedule For Update'

export interface IGetEventScheduleForUpdate {
    type: typeof GET_EVENT_SCHEDULE_FOR_UPDATE
    payload: IEventScheduleSingle
}

export const GET_EVENT_SCHEDULE_TOPIC_FOR_UPDATE = '[ EVENTS ] Get Event Schedule Topic For Update'

export interface IGetEventScheduleTopicForUpdate {
    type: typeof GET_EVENT_SCHEDULE_TOPIC_FOR_UPDATE
    payload: IEventScheduleTopicSingle
}

export const GET_EVENT_SPONSOR_FOR_UPDATE = '[ EVENTS ] Get Event Sponsor For Update'

export interface IGetEventSponsorForUpdate {
    type: typeof GET_EVENT_SPONSOR_FOR_UPDATE
    payload: IEventSponsorSingle
}

export const GET_EVENT_INSCRIPTION_FOR_UPDATE = '[ EVENTS ] Get Event Inscription For Update'

export interface IGetEventInscriptionForUpdate {
    type: typeof GET_EVENT_INSCRIPTION_FOR_UPDATE
    payload: IEventInscriptionSingle
}

// Is update state

export const IS_EVENT_FOR_UPDATE = '[ EVENTS ] Is Event For Update'

export interface IIsEventForUpdate {
    type: typeof IS_EVENT_FOR_UPDATE
    payload: boolean
}

export const IS_EVENT_SPEAKER_FOR_UPDATE = '[ EVENTS ] Is Event Speaker For Update'

export interface IIsEventSpeakerForUpdate {
    type: typeof IS_EVENT_SPEAKER_FOR_UPDATE
    payload: boolean
}

export const IS_EVENT_SCHEDULE_FOR_UPDATE = '[ EVENTS ] Is Event Schedule For Update'

export interface IIsEventScheduleForUpdate {
    type: typeof IS_EVENT_SCHEDULE_FOR_UPDATE
    payload: boolean
}

export const IS_EVENT_SCHEDULE_TOPIC_FOR_UPDATE = '[ EVENTS ] Is Event Schedule Topic For Update'

export interface IIsEventScheduleTopicForUpdate {
    type: typeof IS_EVENT_SCHEDULE_TOPIC_FOR_UPDATE
    payload: boolean
}

export const IS_EVENT_SPONSOR_FOR_UPDATE = '[ EVENTS ] Is Event Sponsor For Update'

export interface IIsEventSponsorForUpdate {
    type: typeof IS_EVENT_SPONSOR_FOR_UPDATE
    payload: boolean
}

export const IS_EVENT_INSCRIPTION_FOR_UPDATE = '[ EVENTS ] Is Event Inscription For Update'

export interface IIsEventInscriptionForUpdate {
    type: typeof IS_EVENT_INSCRIPTION_FOR_UPDATE
    payload: boolean
}

//UPDATE table

export const SWITCH_EVENT_REFRESH_TABLE = '[ EVENTS ] Switch Event Refresh Table'

export interface ISwitchEventRefreshTable {
    type: typeof SWITCH_EVENT_REFRESH_TABLE
    payload: boolean
}

export const SWITCH_EVENT_SPEAKER_REFRESH_TABLE = '[ EVENTS ] Switch Event Speaker Refresh Table'

export interface ISwitchEventSpeakerRefreshTable {
    type: typeof SWITCH_EVENT_SPEAKER_REFRESH_TABLE
    payload: boolean
}

export const SWITCH_EVENT_SCHEDULE_REFRESH_TABLE = '[ EVENTS ] Switch Event Schedule Refresh Table'

export interface ISwitchEventScheduleRefreshTable {
    type: typeof SWITCH_EVENT_SCHEDULE_REFRESH_TABLE
    payload: boolean
}

export const SWITCH_EVENT_SCHEDULE_TOPIC_REFRESH_TABLE = '[ EVENTS ] Switch Event Schedule Topic Refresh Table'

export interface ISwitchEventScheduleTopicRefreshTable {
    type: typeof SWITCH_EVENT_SCHEDULE_TOPIC_REFRESH_TABLE
    payload: boolean
}

export const SWITCH_EVENT_SPONSOR_REFRESH_TABLE = '[ EVENTS ] Switch Event Sponsor Refresh Table'

export interface ISwitchEventSponsorRefreshTable {
    type: typeof SWITCH_EVENT_SPONSOR_REFRESH_TABLE
    payload: boolean
}

export const SWITCH_EVENT_INSCRIPTION_REFRESH_TABLE = '[ EVENTS ] Switch Event Inscription Refresh Table'

export interface ISwitchEventInscriptionRefreshTable {
    type: typeof SWITCH_EVENT_INSCRIPTION_REFRESH_TABLE
    payload: boolean
}