import * as yup from 'yup'

export const validateCreateClinicalCases = yup.object().shape({
    title: yup
    .string()
    .required('Campo requerido.')
    .min(2, 'El Título debe tener más de 2 caracteres'),
    id_form: yup
    .string()
    .min(2, 'El ID DEL FORMATO debe tener más de 2 caracteres'),
    age_classification: yup
    .string()
    .min(1, 'LA CLASIFICACIÓN DE EDAD de edad debe tener mínimo de 1 caracteres'),
    slug: yup
    .string()
    .required('Campo requerido.')
    .min(2, 'El SLUG debe tener más de 2 caracteres'),
    natural_and_coming: yup
    .string()
    .min(2, 'NATURAL Y VENIDERA/O debe tener más de 2 caracteres'),
    gender: yup
    .string()
    .oneOf(['M','F'], 'Debes elegir entre estas opciones'),
    name: yup
    .string()
    .min(4, 'El NOMBRE debe tener más de 3 caracteres'),
    reason_for_consultation: yup
    .string()
    .min(2, 'LA RAZÓN DE LA CONSULTA debe tener más de 2 caracteres'),
    html_body: yup
    .string()
    .required('Campo requerido.')
    .min(2, 'EL CUERPO DEL MENSAJE debe tener más de 2 caracteres'),
    // client_page: yup
    // .string()
    // .required('Campo requerido.')
    // .oneOf(['scp', 'residents'], 'Debes elegir entre estas opciones'),
    // status: yup
    // .string()
    // .required('Campo requerido.')
    // .oneOf(['active', 'inactive'], 'Debes elegir entre estas opciones'),
})
