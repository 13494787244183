import { TRootState } from '../@types/redux'

//modals
export const selectModalEventSpeaker = (state: TRootState) => state.events.eventSpeakersModalIsOpen
export const selectModalEventSchedule = (state: TRootState) => state.events.eventSchedulesModalIsOpen
export const selectModalEventScheduleTopic = (state: TRootState) => state.events.eventScheduleTopicsModalIsOpen
export const selectModalEventSponsor = (state: TRootState) => state.events.eventSponsorsModalIsOpen
export const selectModalEventInscription = (state: TRootState) => state.events.eventInscriptionsModalIsOpen
// state for updating?
export const selectIsUpdateEvent = (state: TRootState) => state.events.isUpdateEvents
export const selectIsUpdateEventSpeaker = (state: TRootState) => state.events.isUpdateEventSpeakers
export const selectIsUpdateEventSchedule = (state: TRootState) => state.events.isUpdateEventSchedules
export const selectIsUpdateEventScheduleTopic = (state: TRootState) => state.events.isUpdateEventScheduleTopics
export const selectIsUpdateEventSponsor = (state: TRootState) => state.events.isUpdateEventSponsors
export const selectIsUpdateEventInscription = (state: TRootState) => state.events.isUpdateEventInscriptions
//single data
export const selectEventForUpdate = (state: TRootState) => state.events.eventsForUpdate
export const selectForUpdateEventSpeakerForUpdate = (state: TRootState) => state.events.eventSpeakerForUpdate
export const selectForUpdateEventSchedule = (state: TRootState) => state.events.eventScheduleForUpdate
export const selectForUpdateEventScheduleTopic = (state: TRootState) => state.events.eventScheduleTopicForUpdate
export const selectForUpdateEventSponsor = (state: TRootState) => state.events.eventSponsorForUpdate
export const selectForUpdateEventInscription = (state: TRootState) => state.events.eventInscriptionForUpdate
//UPDATING tables
export const selectEventRefreshTable = (state: TRootState) => state.events.refreshTableEvents
export const selectRefreshTableEventSpeaker = (state: TRootState) => state.events.refreshTableEventSpeakers
export const selectRefreshTableEventSchedule = (state: TRootState) => state.events.refreshTableEventSchedules
export const selectRefreshTableEventScheduleTopic = (state: TRootState) => state.events.refreshTableEventScheduleTopics
export const selectRefreshTableEventSponsor = (state: TRootState) => state.events.refreshTableEventSponsors
export const selectRefreshTableEventInscription = (state: TRootState) => state.events.refreshTableEventInscriptions