import { message, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import { useEventsSpeakers } from "./useEventsSpeakers";
import {
  deleteEventSpeaker,
  eventSpeakerChangeModalState,
  refreshTableEventSpeaker,
  setEventSpeakerForUpdate,
  setIsEventSpeakerForUpdate,
} from "../../../../redux";

const { confirm } = Modal;

export const useTableEventsSpeakers = () => {
  const dispatch = useAppDispatch();
  const { tableProps } = useEventsSpeakers();

  const changeEventSpeakerForUpdate = (item: any) => {
    dispatch(setIsEventSpeakerForUpdate(true));
    dispatch(setEventSpeakerForUpdate(item));
    dispatch(eventSpeakerChangeModalState());
  };

  const showDeleteConfirm = (valId: number, val: string) => {
    confirm({
      title: `Estás seguro de borrar el conferencista con el id: ${valId}`,
      icon: <ExclamationCircleOutlined />,
      content: `Una vez ejecutada esta acción no hay vuelta atrás, estás por borrar a "${val}", si deseas continuar dale click a "Borrar conferencista" en caso tal no quieras, solo oprime en "Cancelar".`,
      okText: "Borrar conferencista",
      okType: "danger",
      cancelText: "Cancelar",
      keyboard: true,
      maskClosable: true,
      async onOk() {
        await deleteEventSpeaker(valId)
          .then(() => {
            dispatch(refreshTableEventSpeaker(true));
            message.success(`Se ha borrado el conferencista con id:${valId}`);
            tableProps?.refresh();
          })
          .catch((err) => {
            console.log({ err });
            message.error(err.message);
          });
      },
      onCancel() {},
    });
  };
  return {
    //state
    //methods
    //functions
    changeEventSpeakerForUpdate,
    showDeleteConfirm,
  };
};
