import { NinjaFormsWebhook } from "../modules"

interface Props {
    isSCP: boolean
}

export const NinjaFormsWebhookPage = ({isSCP}:Props) => {
  return (
    <NinjaFormsWebhook  isSCP={isSCP}/>
  )
}