import { EventsByID } from "../modules"

interface Props {
    isSCP: boolean
}

export const EventsByIDPage = ({isSCP}:Props) => {
  return (
    <EventsByID isSCP={isSCP}/>
  )
}
