import * as yup from 'yup'

export const validateUpdateUsers = yup.object().shape({
    name: yup
    .string()
    .min(4, 'El NOMBRE debe tener más de 3 caracteres')
    .nullable()
    .required("campo requerido"),
    last_name: yup
    .string()
    .min(4, 'El APELLIDO debe tener más de 3 caracteres')
    .nullable()
    .required("campo requerido"),
    email: yup
    .string()
    .email('Introduzca un correo electrónico válido.')
    .nullable()
    .required("campo requerido"),
    password: yup
    .string()
    .min(6, 'Debe introducir una contraseña de al menos 6 caracteres.'),
    role: yup
    .string()
    .oneOf(['admin'], 'Debes elegir entre estas opciones'),
    status: yup
    .string()
    .oneOf(['active', 'inactive'], 'Debes elegir entre estas opciones'),
})

