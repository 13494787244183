import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { message } from "antd";
import { useSelector } from "react-redux";

import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import {
  createEventSpeaker,
  eventSpeakerChangeModalState,
  refreshTableEventSpeaker,
  selectEventRefreshTable,
  selectForUpdateEventSpeakerForUpdate,
  selectIsUpdateEventSpeaker,
  selectRefreshTableEventSpeaker,
  setIsEventSpeakerForUpdate,
  updateEventSpeaker,
} from "../../../../redux";
import { TableProperties } from "../../../../shared/components/ui/Table/types";
import { IEventSpeakerSingle } from "../../../../redux/@types";
import {
  validateCreateEventSpeaker,
  validateUpdateEventSpeaker,
} from "../../../../helpers";

export const useEventsSpeakers = () => {
  const dispatch = useAppDispatch();
  const locationPath = useLocation();
  const { id: idForSpeaker } = useParams();
  const isUpdateEventSpeaker = useSelector(selectIsUpdateEventSpeaker);
  const dataUpdateEventSpeaker = useSelector(
    selectForUpdateEventSpeakerForUpdate
  );
  const isRefreshTableEventSpeakers = useSelector(
    selectRefreshTableEventSpeaker
  );

  const [isLoading, setIsLoading] = useState(false);
  const [tableProps, setTableProps] = useState<TableProperties>();

  const navigateEventSpeakerCreate = () => {
    dispatch(setIsEventSpeakerForUpdate(false));
    dispatch(eventSpeakerChangeModalState());
  };

  const changeModalEventSpeakerState = () => {
    dispatch(eventSpeakerChangeModalState());
  };

  const formMethodsCreate = useForm<IEventSpeakerSingle>({
    resolver: yupResolver(validateCreateEventSpeaker),
  });

  const formMethodsUpdate = useForm<IEventSpeakerSingle>({
    resolver: yupResolver(validateUpdateEventSpeaker),
  });
  //console.log({ dataUpdateEventSpeaker });
  useEffect(() => {
    if (isUpdateEventSpeaker) {
      formMethodsCreate.reset();
      formMethodsUpdate.reset({ ...dataUpdateEventSpeaker });
    }

    return () => {
      formMethodsUpdate.reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateEventSpeaker, dataUpdateEventSpeaker]);

  const onSubmitCreateOrUpdate = async (data: IEventSpeakerSingle) => {
    // console.log({ data });
    if (isLoading) return;
    setIsLoading(true);
    if (isUpdateEventSpeaker) {
      // console.log("Actualiando===>", data);
      await updateEventSpeaker({
        id: data.id,
        name: data.name,
        cargo: data.cargo,
        image_path: data.image_path,
        // event_id: idForSpeaker,
        priority: data.priority,
      })
        .then((res: Partial<IEventSpeakerSingle>) => {
          setIsLoading(false);
          // dispatch(mappingUpdatedUser(res))
          if (res.id) {
            // navigate(`/event/${locationPath.pathname.split("/")[2]}`);
            message.success(
              `Se ha actualizado con éxito el conferencista con id:${res.id}`
            );
            dispatch(refreshTableEventSpeaker(true));
          } else {
            message.error(res as string);
          }
        })
        .catch((err: any) => {
          setIsLoading(false);
          console.log(err);
          message.error(err.message);
        });
    } else {
      // console.log('Creando===>',data)
      // setIsLoading(false);
      await createEventSpeaker({
        name: data.name,
        cargo: data.cargo,
        image_path: data.image_path,
        event_id: idForSpeaker,
        priority: data.priority,
      })
        .then((res: any) => {
          setIsLoading(false);
          if (res.id) {
            dispatch(eventSpeakerChangeModalState());
            // navigate(`/event/${locationPath.pathname.split("/")[2]}`);
            message.success(
              `Se ha creado con éxito el conferencista con id:${res.id}`
            );
            dispatch(refreshTableEventSpeaker(true));
          } else {
            message.error(res as any);
          }
        })
        .catch((err: any) => {
          setIsLoading(false);
          console.log(err);
          message.error(err.message);
        });
    }
  };

  useEffect(() => {
    if (isRefreshTableEventSpeakers) {
      tableProps?.refresh();
      dispatch(refreshTableEventSpeaker(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefreshTableEventSpeakers]);

  return {
    //state
    isUpdateEventSpeaker,
    isLoading,
    tableProps,
    locationPath,
    idForSpeaker,
    //methods
    formMethodsCreate,
    formMethodsUpdate,
    setTableProps,
    //functions
    navigateEventSpeakerCreate,
    onSubmitCreateOrUpdate,
    changeModalEventSpeakerState,
  };
};
