import * as yup from 'yup'

export const validateUpdateEventScheduleTopic = yup.object().shape({
    start_at:yup
    .string()
    .min(1, 'La FECHA DE INICIO debe tener más de 1 caracteres'),
    end_at:yup
    .string()
    .min(1, 'La FECHA DE FINALIZACIÓN debe tener más de 1 caracteres'),
    speaker_details: yup
    .string()
    .nullable(true)
    .min(2, 'LOS DETALLES DEL CONFERENCISTA debe tener más de 2 caracteres'),
    topic: yup
    .string()
    .nullable(true)
    .min(2, 'EL TEMA debe tener más de 2 caracteres'),
    moderador: yup
    .string()
    .nullable(true),
    // .required('Campo requerido.')
    // .min(2, 'EL MODERADOR debe tener más de 2 caracteres'),
    is_moderador: yup
    .boolean()
    // .required('Campo requerido.')
    .oneOf([false, true], 'Debes elegir entre estas opciones'),
})