import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { message } from "antd";
import { useSelector } from "react-redux";

import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import {
  createEventSchedule,
  eventScheduleChangeModalState,
  refreshTableEventSchedule,
  selectForUpdateEventSchedule,
  selectIsUpdateEventSchedule,
  selectRefreshTableEventSchedule,
  setIsEventScheduleForUpdate,
  updateEventSchedule,
} from "../../../../redux";
import { TableProperties } from "../../../../shared/components/ui/Table/types";
import { IEventScheduleSingle } from "../../../../redux/@types";
import {
  validateCreateEventSchedule,
  validateUpdateEventSchedule,
} from "../../../../helpers";
import moment from "moment";

export const useEventsSchedules = () => {
  const dispatch = useAppDispatch();
  const locationPath = useLocation();
  const { id: idForSchedule } = useParams();
  const isUpdateEventSchedule = useSelector(selectIsUpdateEventSchedule);
  const dataUpdateEventSchedule = useSelector(selectForUpdateEventSchedule);
  const isRefreshTableEventSchedules = useSelector(
    selectRefreshTableEventSchedule
  );

  const [isLoading, setIsLoading] = useState(false);
  const [tableProps, setTableProps] = useState<TableProperties>();

  const navigateEventScheduleCreate = () => {
    dispatch(setIsEventScheduleForUpdate(false));
    dispatch(eventScheduleChangeModalState());
  };

  const changeModalEventScheduleState = () => {
    dispatch(eventScheduleChangeModalState());
  };

  const formMethodsCreate = useForm<IEventScheduleSingle>({
    resolver: yupResolver(validateCreateEventSchedule),
  });

  const formMethodsUpdate = useForm<IEventScheduleSingle>({
    resolver: yupResolver(validateUpdateEventSchedule),
  });

  useEffect(() => {
    if (isUpdateEventSchedule) {
      formMethodsCreate.reset();
      formMethodsUpdate.reset({ ...dataUpdateEventSchedule });
    }

    return () => {
      formMethodsUpdate.reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateEventSchedule, dataUpdateEventSchedule]);

  const onSubmitCreateOrUpdate = async (data: IEventScheduleSingle) => {
    // console.log({ data });
    if (isLoading) return;
    setIsLoading(true);
    if (isUpdateEventSchedule) {
      // console.log("Actualizando===>", data);
      await updateEventSchedule({
        id: data.id,
        date: moment(data.date).format("YYYY-MM-DD"),
        moderator: data.moderator,
      })
        .then((res: Partial<IEventScheduleSingle>) => {
          setIsLoading(false);
          // dispatch(mappingUpdatedUser(res))
          if (res.id) {
            // navigate(`/event/${locationPath.pathname.split("/")[2]}`);
            message.success(
              `Se ha actualizado con éxito el programa académico con id:${res.id}`
            );
            dispatch(refreshTableEventSchedule(true));
          } else {
            message.error(res as string);
          }
        })
        .catch((err: any) => {
          setIsLoading(false);
          console.log(err);
          message.error(err.message);
        });
    } else {
      // console.log('Creando===>',data)
      // setIsLoading(false);
      await createEventSchedule({
        events_id: idForSchedule,
        date: moment(data.date).format("YYYY-MM-DD"),
        moderator: data.moderator,
      })
        .then((res: any) => {
          setIsLoading(false);
          if (res.id) {
            dispatch(eventScheduleChangeModalState());
            // navigate(`/event/${locationPath.pathname.split("/")[2]}`);
            message.success(
              `Se ha creado con éxito el programa académico con id:${res.id}`
            );
            dispatch(refreshTableEventSchedule(true));
          } else {
            message.error(res as any);
          }
        })
        .catch((err: any) => {
          setIsLoading(false);
          console.log(err);
          message.error(err.message);
        });
    }
  };

  useEffect(() => {
    if (isRefreshTableEventSchedules) {
      tableProps?.refresh();
      dispatch(refreshTableEventSchedule(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefreshTableEventSchedules]);

  return {
    //state
    isUpdateEventSchedule,
    isLoading,
    tableProps,
    locationPath,
    idForSchedule,
    //methods
    formMethodsCreate,
    formMethodsUpdate,
    setTableProps,
    //functions
    navigateEventScheduleCreate,
    onSubmitCreateOrUpdate,
    changeModalEventScheduleState,
  };
};
