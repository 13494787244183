import { Button, Col, Row, Typography } from "antd";
import { FormProvider, useFormContext } from "react-hook-form";
import {
  InputPassword,
  InputText,
  LoginLayout,
} from "../../../shared/components";
import { useLogin, FormData } from "../hooks/useLogin";
import { WrapperCardLogin, WrapperColLogin } from "./styled";

const { Title } = Typography;

export const Login = () => {
  const { isLoading, onLoginUser } = useLogin();
  const { control, handleSubmit: onSubmit } = useFormContext<FormData>();

  return (
    <LoginLayout pageDescription="Login For Users" title="Login">
      <Row justify="center" align="middle">
        <WrapperColLogin span={24}>
          <WrapperCardLogin>
            <div
              style={{
                justifyContent: "center",
                alignContent: "center",
                width: "100%",
                height: "100%",
                display: "flex",
              }}
            >
              <img
                src={"/logo_scp.png"}
                alt="logoCasandra"
                style={{
                  width: "98px",
                  height: "83px",
                }}
              />
            </div>
            <Col
              span={24}
              style={{
                display: "flex",
                marginTop: "10px",
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <Title style={{ marginBottom: "1.5px" }} level={5}>
                Email:
              </Title>
              <InputText
                placeHolder="example@example.com"
                name="email"
                control={control}
              />
            </Col>
            <Col
              span={24}
              style={{
                display: "flex",
                marginTop: "10px",
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <Title style={{ marginBottom: "1.5px" }} level={5}>
                Contraseña:
              </Title>
              <InputPassword name="password" control={control} />
            </Col>
            <Col
              span={24}
              style={{
                display: "flex",
                marginTop: "18px",
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <Button
                style={{ width: "100%" }}
                type="primary"
                shape="round"
                size="large"
                onClick={onSubmit(onLoginUser)}
                loading={isLoading}
              >
                Ingresar
              </Button>
            </Col>
          </WrapperCardLogin>
        </WrapperColLogin>
      </Row>
    </LoginLayout>
  );
};
