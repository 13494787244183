import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import { useSelector } from 'react-redux';
import { authUserLogout, selectCollapsed, selectUser, uiChangeCollapsedState } from "../../../../redux";
import { useNavigate } from "react-router-dom";

export const useNavBar = () => {
    const dispatch = useAppDispatch();
    const user = useSelector(selectUser);
    const navigate = useNavigate();
    const collapsed = useSelector(selectCollapsed);

    const logout =() =>{
        localStorage.removeItem('jwt')
        localStorage.removeItem('user')
        dispatch(authUserLogout())
        navigate('/login')
    }

    const changeCollapsed =()=>{
      dispatch(uiChangeCollapsedState())
    }

  return {
    //state
    user,
    //methods
    //functions
    logout,
    changeCollapsed,
  }
}
