import * as yup from 'yup'

export const validateCreateEvent = yup.object().shape({
    title: yup
    .string()
    .required('Campo requerido.')
    .min(2, 'El Título debe tener más de 2 caracteres'),
    // slug: yup
    // .string()
    // .required('Campo requerido.')
    // .min(2, 'El SLUG debe tener más de 2 caracteres'),
    start_date:yup
    .string()
    .min(1, 'La FECHA DE INICIO debe tener más de 1 caracteres'),
    end_date:yup
    .string()
    .min(1, 'La FECHA DE FINALIZACIÓN debe tener más de 1 caracteres'),
    html_body: yup
    .string()
    .required('Campo requerido.')
    .min(2, 'EL CUERPO DEL MENSAJE debe tener más de 2 caracteres'),
    web_url: yup
    .string()
    .min(2, 'LA URL WEB debe tener más de 2 caracteres'),
    html_short_description: yup
    .string()
    .min(2, 'LA DESCRIPCIÓN CORTA debe tener más de 2 caracteres'),
    event_location: yup
    .string()
    .min(2, 'LA LOCACIÓN DEL EVENTO debe tener más de 2 caracteres'),
})
