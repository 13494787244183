import { Spin } from "antd";
import { FormProvider } from "react-hook-form";

import { AdminMenuLayout } from "../../../../shared/components";
import { useEvents, useEventsById } from "../../hooks";
import { WrapperCardEvents, WrapperTabNav } from "../styled";
import { EventsForm } from "./EventsForm";
import { EventsInscriptions } from "./EventsInscriptions";
import { EventsSchedules } from "./EventsSchedule";
import { EventsSpeakers } from "./EventsSpeakers";
import { EventsSponsors } from "./EventsSponsors";

interface Props {
  isSCP: boolean;
}

export const EventsByID = ({ isSCP }: Props) => {
  const { isUpdateEvent, formMethodsCreate, formMethodsUpdate } = useEvents();
  const { isValidate } = useEventsById();
  return (
    <AdminMenuLayout pageDescription="ProductsById" title="ProductsById">
      <WrapperCardEvents>
        {!isValidate ? (
          <Spin size="large" />
        ) : (
          <>
            {!isUpdateEvent && (
              <FormProvider {...formMethodsCreate}>
                <EventsForm isSCP={isSCP} />
              </FormProvider>
            )}
            {isUpdateEvent && (
              <WrapperTabNav
                items={[
                  {
                    label: `EVENTO`,
                    key: "1",
                    children: (
                      <>
                        <FormProvider {...formMethodsUpdate}>
                          <EventsForm isSCP={isSCP} />
                        </FormProvider>
                      </>
                    ),
                  },

                  ...(isSCP
                    ? [
                        {
                          label: `CONFERENCISTAS`,
                          key: "2",
                          children: <EventsSpeakers />,
                        },
                        {
                          label: `PROGRAMA ACADÉMICO`,
                          key: "3",
                          children: <EventsSchedules />,
                        },
                        {
                          label: `SPONSORS`,
                          key: "4",
                          children: <EventsSponsors />,
                        },
                        {
                          label: `INSCRIPCIONES`,
                          key: "5",
                          children: <EventsInscriptions />,
                        },
                      ]
                    : []),
                  // {
                  //   label: `CARACT. DEL PRODUCTO`,
                  //   key: "3",
                  //   children: <TableProductCharacteristics />,
                  // },
                ]}
              />
            )}
          </>
        )}
      </WrapperCardEvents>
    </AdminMenuLayout>
  );
};
