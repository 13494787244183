import { Navigate, Route, Routes } from "react-router-dom";

import {
  UsersPage,
  LoginPage,
  ClinicalCasesPage,
  ClinicalCasesByIDPage,
  LeadsPage,
  RequestsOfDataUpdatePage,
  EventsPage,
  EventsByIDPage,
  NinjaFormsWebhookPage,
} from "../pages";
import { useAppRouter } from "./hooks";

export const AppRouter = () => {
  const { auth } = useAppRouter();
  return (
    <Routes>
      <Route path="/" element={auth === true ? <Navigate to="/orders" replace/> : <Navigate to="/login" replace/>} />
      { auth &&(
        <>
        <Route  path="/users" element={<UsersPage />} />
        <Route  path="/clinicalCases/SCP" element={<ClinicalCasesPage />} />
        <Route  path="/clinicalCases/RESIDENTES" element={<ClinicalCasesPage />} />
        <Route  path="/clinicalCases/SCP/:id" element={<ClinicalCasesByIDPage />} />
        <Route  path="/clinicalCases/RESIDENTES/:id" element={<ClinicalCasesByIDPage />} />
        <Route  path="/leads" element={<LeadsPage />} />
        <Route  path="/requestsOfDataUpdate" element={<RequestsOfDataUpdatePage />} />
        <Route  path="/events/scp" element={<EventsPage isSCP={true} />} />
        <Route  path="/events/residents" element={<EventsPage isSCP={false} />} />
        <Route  path="/events/scp/:id" element={<EventsByIDPage isSCP={true} />} />
        <Route  path="/events/residents/:id" element={<EventsByIDPage isSCP={false} />} />
        <Route  path="/ninjaFormsWebhook/scp" element={<NinjaFormsWebhookPage isSCP={true} />} />
        <Route  path="/ninjaFormsWebhook/residents" element={<NinjaFormsWebhookPage isSCP={false} />} />
        <Route path="/*" element={<Navigate to="/users" replace/>} />
        </>
      )}
      { !auth &&(
        <>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/*" element={<Navigate to="/login" replace/>} />
        </>
      )}
    </Routes>
  );
};
