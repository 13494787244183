import { Avatar, Button, Col } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

import { IEventSponsorSingle } from "../../../../redux/@types";
import { TableColumns } from "../../../../shared/components/ui/Table/types";
import { eventsSponsorsService } from "../../../../shared/services";
import { PATH_S3_BUCKET } from "../../../../shared/utils/constants";
import { useEventsSponsors, useTableEventsSponsors } from "../../hooks";
import Table from "../../../../shared/components/ui/Table";

export const TableEventsSponsors = () => {
  const { setTableProps, idForSponsor } = useEventsSponsors();
  const { changeEventSponsorForUpdate, showDeleteConfirm } =
    useTableEventsSponsors();

  const columns: TableColumns<IEventSponsorSingle> = [
    {
      title: "ID",
      width: 3,
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Nombre",
      width: 8,
      dataIndex: "name",
      key: "name",
      filterType: "text",
    },
    {
      title: "Foto",
      width: 5,
      dataIndex: "image_path",
      key: "image_path",
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              flexDirection: "row",
            }}
          >
            {item.image_path && (
              <Avatar size={64} src={`${PATH_S3_BUCKET}/${item.image_path}`} />
            )}
          </Col>
        );
      },
    },
    {
      title: "Prioridad",
      width: 3,
      dataIndex: "priority",
      key: "priority",
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "right",
      width: 3,
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              flexDirection: "row",
            }}
          >
            <Button
              type="primary"
              shape="circle"
              onClick={() => changeEventSponsorForUpdate(item)}
              icon={<EditOutlined />}
              size="large"
            />
            <Button
              type="primary"
              danger
              onClick={() => showDeleteConfirm(item.id, `${item.name}`)}
              shape="circle"
              icon={<DeleteOutlined />}
              size="large"
            />
          </Col>
        );
      },
    },
  ];
  return (
    <Table<IEventSponsorSingle>
      columns={columns}
      fetchQueryProps={{
        $sort: { id: -1 },
        event_id: idForSponsor,
      }}
      service={eventsSponsorsService}
      onLoad={setTableProps}
    />
  );
};
