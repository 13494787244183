import { eventsActions } from "../action-types";
import { IEventSingle, IEventSpeakerSingle, IEventScheduleSingle, IEventScheduleTopicSingle, IEventSponsorSingle, IEventInscriptionSingle } from '../@types/events';
import { eventsInscriptionsService, eventsScheduleService, eventsScheduleTopicsService, eventsService, eventsSpeakersService, eventsSponsorsService } from "../../shared/services";

//actions modals

export const eventSpeakerChangeModalState = (): eventsActions.IEventSpeakerChangeModalState => ({
    type: eventsActions.EVENT_SPEAKER_CHANGE_MODAL_STATE
})

export const eventScheduleChangeModalState = (): eventsActions.IEventScheduleChangeModalState => ({
    type: eventsActions.EVENT_SCHEDULE_CHANGE_MODAL_STATE
})

export const eventScheduleTopicChangeModalState = (): eventsActions.IEventScheduleTopicChangeModalState => ({
    type: eventsActions.EVENT_SCHEDULE_TOPIC_CHANGE_MODAL_STATE
})

export const eventSponsorChangeModalState = (): eventsActions.IEventSponsorChangeModalState => ({
    type: eventsActions.EVENT_SPONSOR_CHANGE_MODAL_STATE
})

export const eventInscriptionChangeModalState = (): eventsActions.IEventInscriptionChangeModalState => ({
    type: eventsActions.EVENT_INSCRIPTION_CHANGE_MODAL_STATE
})

//actions for update state

export const setEventForUpdate = ( val: IEventSingle ): eventsActions.IGetEventForUpdate =>({
    type: eventsActions.GET_EVENT_FOR_UPDATE,
    payload: val
})

export const setEventSpeakerForUpdate = ( val: IEventSpeakerSingle ): eventsActions.IGetEventSpeakerForUpdate =>({
    type: eventsActions.GET_EVENT_SPEAKER_FOR_UPDATE,
    payload: val
})

export const setEventScheduleForUpdate = ( val: IEventScheduleSingle ): eventsActions.IGetEventScheduleForUpdate =>({
    type: eventsActions.GET_EVENT_SCHEDULE_FOR_UPDATE,
    payload: val
})

export const setEventScheduleTopicForUpdate = ( val: IEventScheduleTopicSingle ): eventsActions.IGetEventScheduleTopicForUpdate =>({
    type: eventsActions.GET_EVENT_SCHEDULE_TOPIC_FOR_UPDATE,
    payload: val
})

export const setEventSponsorForUpdate = ( val: IEventSponsorSingle ): eventsActions.IGetEventSponsorForUpdate =>({
    type: eventsActions.GET_EVENT_SPONSOR_FOR_UPDATE,
    payload: val
})

export const setEventInscriptionForUpdate = ( val: IEventInscriptionSingle ): eventsActions.IGetEventInscriptionForUpdate =>({
    type: eventsActions.GET_EVENT_INSCRIPTION_FOR_UPDATE,
    payload: val
})

// change state update or not update

export const setIsEventForUpdate = (val: boolean ): eventsActions.IIsEventForUpdate => ({
    type: eventsActions.IS_EVENT_FOR_UPDATE,
    payload: val
})

export const setIsEventSpeakerForUpdate = (val: boolean ): eventsActions.IIsEventSpeakerForUpdate => ({
    type: eventsActions.IS_EVENT_SPEAKER_FOR_UPDATE,
    payload: val
})

export const setIsEventScheduleForUpdate = (val: boolean ): eventsActions.IIsEventScheduleForUpdate => ({
    type: eventsActions.IS_EVENT_SCHEDULE_FOR_UPDATE,
    payload: val
})

export const setIsEventScheduleTopicForUpdate = (val: boolean ): eventsActions.IIsEventScheduleTopicForUpdate => ({
    type: eventsActions.IS_EVENT_SCHEDULE_TOPIC_FOR_UPDATE,
    payload: val
})

export const setIsEventSponsorForUpdate = (val: boolean ): eventsActions.IIsEventSponsorForUpdate => ({
    type: eventsActions.IS_EVENT_SPONSOR_FOR_UPDATE,
    payload: val
})

export const setIsEventInscriptionForUpdate = (val: boolean ): eventsActions.IIsEventInscriptionForUpdate => ({
    type: eventsActions.IS_EVENT_INSCRIPTION_FOR_UPDATE,
    payload: val
})

//Create services

export const createEvent =async( data: Partial<IEventSingle> ) => {
    return await eventsService.create(data)
    .then((res: IEventSingle) => {return res})
    .catch((err: any) => { return err.message })
}

export const createEventSpeaker =async( data: Partial<IEventSpeakerSingle> ) => {
    return await eventsSpeakersService.create(data)
    .then((res: IEventSpeakerSingle) => {return res})
    .catch((err: any) => { return err.message })
}

export const createEventSchedule =async( data: Partial<IEventScheduleSingle> ) => {
    return await eventsScheduleService.create(data)
    .then((res: IEventScheduleSingle) => {return res})
    .catch((err: any) => { return err.message })
}

export const createEventScheduleTopic =async( data: Partial<IEventScheduleTopicSingle> ) => {
    return await eventsScheduleTopicsService.create(data)
    .then((res: IEventScheduleTopicSingle) => {return res})
    .catch((err: any) => { return err.message })
}

export const createEventSponsor =async( data: Partial<IEventSponsorSingle> ) => {
    return await eventsSponsorsService.create(data)
    .then((res: IEventSponsorSingle) => {return res})
    .catch((err: any) => { return err.message })
}

export const createEventInscription =async( data: Partial<IEventInscriptionSingle> ) => {
    return await eventsInscriptionsService.create(data)
    .then((res: IEventInscriptionSingle) => {return res})
    .catch((err: any) => { return err.message })
}

//Update services

export const updateEvent =async( data: Partial<IEventSingle> ) => {
    return await eventsService.patch(data?.id!,data)
    .then((res: IEventSingle) => {return res})
    .catch((err: any) => { return err.message })
}

export const updateEventSpeaker =async( data: Partial<IEventSpeakerSingle> ) => {
    return await eventsSpeakersService.patch(data?.id!,data)
    .then((res: IEventSpeakerSingle) => {return res})
    .catch((err: any) => { return err.message })
}

export const updateEventSchedule =async( data: Partial<IEventScheduleSingle> ) => {
    return await eventsScheduleService.patch(data?.id!,data)
    .then((res: IEventScheduleSingle) => {return res})
    .catch((err: any) => { return err.message })
}

export const updateEventScheduleTopic =async( data: Partial<IEventScheduleTopicSingle> ) => {
    return await eventsScheduleTopicsService.patch(data?.id!,data)
    .then((res: IEventScheduleTopicSingle) => {return res})
    .catch((err: any) => { return err.message })
}

export const updateEventSponsor =async( data: Partial<IEventSponsorSingle> ) => {
    return await eventsSponsorsService.patch(data?.id!,data)
    .then((res: IEventSponsorSingle) => {return res})
    .catch((err: any) => { return err.message })
}

export const updateEventInscription =async( data: Partial<IEventInscriptionSingle> ) => {
    return await eventsInscriptionsService.patch(data?.id!,data)
    .then((res: IEventInscriptionSingle) => {return res})
    .catch((err: any) => { return err.message })
}

//Delete services

export const deleteEvent =async( id: number ) => {
    return await eventsService.remove(id.toString())
    .then((res: any) => {return res})
    .catch((err: any) => { return err.message })
}

export const deleteEventSpeaker =async( id: number ) => {
    return await eventsSpeakersService.remove(id.toString())
    .then((res: any) => {return res})
    .catch((err: any) => { return err.message })
}

export const deleteEventSchedule =async( id: number ) => {
    return await eventsScheduleService.remove(id.toString())
    .then((res: any) => {return res})
    .catch((err: any) => { return err.message })
}

export const deleteEventScheduleTopic =async( id: number ) => {
    return await eventsScheduleTopicsService.remove(id.toString())
    .then((res: any) => {return res})
    .catch((err: any) => { return err.message })
}

export const deleteEventSponsor =async( id: number ) => {
    return await eventsSponsorsService.remove(id.toString())
    .then((res: any) => {return res})
    .catch((err: any) => { return err.message })
}

export const deleteEventInscription =async( id: number ) => {
    return await eventsInscriptionsService.remove(id.toString())
    .then((res: any) => {return res})
    .catch((err: any) => { return err.message })
}

//refresh tables

export const refreshTableEvent =( val: boolean ): eventsActions.ISwitchEventRefreshTable =>({
    type: eventsActions.SWITCH_EVENT_REFRESH_TABLE,
    payload: val
})

export const refreshTableEventSpeaker =( val: boolean ): eventsActions.ISwitchEventSpeakerRefreshTable =>({
    type: eventsActions.SWITCH_EVENT_SPEAKER_REFRESH_TABLE,
    payload: val
})

export const refreshTableEventSchedule =( val: boolean ): eventsActions.ISwitchEventScheduleRefreshTable =>({
    type: eventsActions.SWITCH_EVENT_SCHEDULE_REFRESH_TABLE,
    payload: val
})

export const refreshTableEventScheduleTopic =( val: boolean ): eventsActions.ISwitchEventScheduleTopicRefreshTable =>({
    type: eventsActions.SWITCH_EVENT_SCHEDULE_TOPIC_REFRESH_TABLE,
    payload: val
})

export const refreshTableEventSponsor =( val: boolean ): eventsActions.ISwitchEventSponsorRefreshTable =>({
    type: eventsActions.SWITCH_EVENT_SPONSOR_REFRESH_TABLE,
    payload: val
})

export const refreshTableEventInscription =( val: boolean ): eventsActions.ISwitchEventInscriptionRefreshTable =>({
    type: eventsActions.SWITCH_EVENT_INSCRIPTION_REFRESH_TABLE,
    payload: val
})