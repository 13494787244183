import { Button, Col, Row } from "antd"
import { DownloadOutlined } from "@ant-design/icons";

import { AdminMenuLayout } from "../../../shared/components"
import { WrapperCardRequestsOfDataUpdate } from "./styled"
import { TableRequestsOfDataUpdate } from "./TableRequestsOfDataUpdate"
import { requestsOfDataUpdateService } from "../../../shared/services";

export const RequestsOfDataUpdate = () => {

  const handleDownload = async () => {
    await requestsOfDataUpdateService.find({ query: { $client: { exportData: 'true' } } }).then((res) => {
        window.open(res.data[0].objectUrl, '_blank')
    })
  }

  return (
    <AdminMenuLayout pageDescription="RequestsOfDataUpdate" title="RequestsOfDataUpdate">
      <WrapperCardRequestsOfDataUpdate>
        <Row
          gutter={[8, 8]}
          style={{
            padding: "10px",
            width: "100%",
          }}
        >
          <Col
            span={24}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              type="primary"
              shape="round"
              icon={<DownloadOutlined />}
              size="large"
              onClick={handleDownload}
            >
              Descargar
            </Button>
          </Col>
            <TableRequestsOfDataUpdate />
        </Row>
      </WrapperCardRequestsOfDataUpdate>
    </AdminMenuLayout>
  )
}
