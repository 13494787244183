import { message, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import { useEventsInscriptions } from "./useEventsInscriptions";
import {
  deleteEventInscription,
  eventInscriptionChangeModalState,
  refreshTableEventInscription,
  setEventInscriptionForUpdate,
  setIsEventInscriptionForUpdate,
} from "../../../../redux";

const { confirm } = Modal;

export const useTableEventsInscriptions = () => {
  const dispatch = useAppDispatch();
  const { tableProps } = useEventsInscriptions();

  const changeEventInscriptionForUpdate = (item: any) => {
    dispatch(setIsEventInscriptionForUpdate(true));
    dispatch(setEventInscriptionForUpdate(item));
    dispatch(eventInscriptionChangeModalState());
  };

  const showDeleteConfirm = (valId: number, val: string) => {
    confirm({
      title: `Estás seguro de borrar la inscripción con el id: ${valId}`,
      icon: <ExclamationCircleOutlined />,
      content: `Una vez ejecutada esta acción no hay vuelta atrás, estás por borrar a "${val}", si deseas continuar dale click a "Borrar inscripción" en caso tal no quieras, solo oprime en "Cancelar".`,
      okText: "Borrar inscripción",
      okType: "danger",
      cancelText: "Cancelar",
      keyboard: true,
      maskClosable: true,
      async onOk() {
        await deleteEventInscription(valId)
          .then(() => {
            dispatch(refreshTableEventInscription(true));
            message.success(`Se ha borrado la inscripción con id:${valId}`);
            tableProps?.refresh();
          })
          .catch((err) => {
            console.log({ err });
            message.error(err.message);
          });
      },
      onCancel() {},
    });
  };
  return {
    //state
    //methods
    //functions
    changeEventInscriptionForUpdate,
    showDeleteConfirm,
  };
};
