import { Select } from "antd";
import { useState } from "react";

const { Option } = Select;

interface Props {
  dataOptions: { value: string | number; title: string }[];
  width?: string;
  maxWidth?: string;
  label: string;
  valueSelect: string | undefined;
  onChange: (val: string) => void;
  showSearch?: boolean;
}

export const SimpleInputSelect = ({
  width = "100%",
  maxWidth = "100%",
  dataOptions,
  label,
  valueSelect,
  showSearch = false,
  onChange,
}: Props) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <Select
        placeholder={label}
        value={valueSelect}
        style={{ width, maxWidth }}
        showSearch={showSearch ? true : false}
        optionFilterProp="children"
        onChange={onChange}
      >
        {dataOptions.map((item, idx) => (
          <Option value={item.value} key={idx + ""}>
            {item.title}
          </Option>
        ))}
      </Select>
    </div>
  );
};
