import { Button, Col, Row, Spin, Typography } from "antd";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";

import { useEventsInscriptions } from "../../../../modules";
import {
  selectForUpdateEventInscription,
  selectIsUpdateEventInscription,
  selectModalEventInscription,
} from "../../../../redux";
import { IEventInscriptionSingle } from "../../../../redux/@types";
import { InputHTML, InputNumber, InputSelect, InputText } from "../inputs";
import { WrapperModal } from "./styled";

const { Title } = Typography;

export const ModalEventInscription = () => {
  const modalEventInscription = useSelector(selectModalEventInscription);
  const isUpdateEventInscription = useSelector(selectIsUpdateEventInscription);
  const updateEventInscription = useSelector(selectForUpdateEventInscription);
  const {
    isLoading,
    changeModalEventInscriptionState,
    onSubmitCreateOrUpdate,
  } = useEventsInscriptions();

  const [isValidate, setIsValidate] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setIsValidate(!isValidate);
    }, 1500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { control, handleSubmit: onSubmit } =
    useFormContext<IEventInscriptionSingle>();
  return (
    <WrapperModal
      title={
        isUpdateEventInscription
          ? `Actualizando inscripción: '${updateEventInscription?.id!}'`
          : `Creando inscripción`
      }
      open={modalEventInscription}
      onOk={() => {}}
      onCancel={changeModalEventInscriptionState}
      width={"90%"}
    >
      {!isValidate ? (
        <Row style={{ width: "100%" }} justify="start">
          <div
            style={{
              width: "100%",
              margin: "50%",
              marginTop: 0,
              marginBottom: 0,
            }}
          >
            <Spin size="large" />
          </div>
        </Row>
      ) : (
        <Row style={{ width: "100%" }} justify="start">
          <Col
            span={12}
            style={{
              display: "flex",
              marginTop: "10px",
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              Título:
            </Title>
            <InputText
              placeHolder="Apertura de SCP"
              name="title"
              control={control}
            />
          </Col>
          <Col
            span={12}
            style={{
              display: "flex",
              marginTop: "10px",
              padding: 5,
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              Precio
            </Title>
            <InputText
              placeHolder="$ 100.000 COP"
              name="price"
              control={control}
            />
          </Col>
          <Col
            span={24}
            style={{
              display: "flex",
              marginTop: "10px",
              padding: 5,
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              Cuerpo del mensaje:
            </Title>
            {isUpdateEventInscription ? (
              <InputHTML
                placeHolder="Es un paciente muy optimo para..."
                name="html_body"
                control={control}
                isUpdating
              />
            ) : (
              <InputHTML
                placeHolder="Es un paciente muy optimo para..."
                name="html_body"
                control={control}
              />
            )}
          </Col>
          <Col
            span={12}
            style={{
              display: "flex",
              marginTop: "10px",
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              Prioridad:
            </Title>
            <InputNumber
              //   stringMode={true}
              placeHolder="8"
              name="priority"
              control={control}
              min
            />
          </Col>
          {isUpdateEventInscription && (
            <Col
              span={12}
              style={{
                display: "flex",
                marginTop: "10px",
                padding: 5,
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <Title style={{ marginBottom: "1.5px" }} level={5}>
                Estado:
              </Title>
              <InputSelect
                name="status"
                control={control}
                dataOptions={[
                  { title: "Activo", value: "active" },
                  { title: "Inactivo", value: "inactive" },
                ]}
                label="Estado"
              />
            </Col>
          )}
          <Col
            span={12}
            style={{
              display: "flex",
              marginTop: "10px",
              padding: 5,
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              URL externa:
            </Title>
            <InputText
              placeHolder="https://www.google.com/"
              name="external_url"
              control={control}
            />
          </Col>
          <Button
            style={{
              width: "100%",
              maxWidth: "300px",
              marginTop: 10,
            }}
            type="primary"
            shape="round"
            size="large"
            onClick={onSubmit(onSubmitCreateOrUpdate)}
            loading={isLoading}
          >
            {isUpdateEventInscription
              ? "Actualizar información"
              : "Crear inscripción"}
          </Button>
        </Row>
      )}
    </WrapperModal>
  );
};
