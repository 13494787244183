import { Button, Col, Row, Spin, Typography } from "antd";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";

import { useEventsScheduleTopics } from "../../../../modules";
import {
  selectForUpdateEventScheduleTopic,
  selectIsUpdateEventScheduleTopic,
  selectModalEventScheduleTopic,
} from "../../../../redux";
import { IEventScheduleTopicSingle } from "../../../../redux/@types";
import { InputData, InputHTML, InputSelect, InputText } from "../inputs";
import { WrapperModal } from "./styled";

const { Title } = Typography;

export const ModalEventScheduleTopic = ({
  events_schedule_id,
}: {
  events_schedule_id?: number;
}) => {
  const modalEventScheduleTopic = useSelector(selectModalEventScheduleTopic);
  const isUpdateEventScheduleTopic = useSelector(
    selectIsUpdateEventScheduleTopic
  );
  const updateEventScheduleTopic = useSelector(
    selectForUpdateEventScheduleTopic
  );
  const {
    isLoading,
    changeModalEventScheduleTopicState,
    onSubmitCreateOrUpdate,
  } = useEventsScheduleTopics();

  const [isValidate, setIsValidate] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setIsValidate(!isValidate);
    }, 1500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //console.log({updateEventScheduleTopic});
  const {
    control,
    handleSubmit: onSubmit,
    setValue,
    watch
  } = useFormContext<IEventScheduleTopicSingle>();
  const end_at = watch("end_at")
  const start_at = watch("start_at")
  // console.log({end_at, start_at});
  // console.log({is_moderador, updateEventScheduleTopic})
  useEffect(() => {
    if (events_schedule_id) {
      setValue("events_schedule_id", events_schedule_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events_schedule_id]);

  return (
    <WrapperModal
      title={
        isUpdateEventScheduleTopic
          ? `Actualizando tema del programa académico: '${updateEventScheduleTopic?.id!}'`
          : `Creando tema del programa académico`
      }
      open={modalEventScheduleTopic}
      onOk={() => {}}
      onCancel={changeModalEventScheduleTopicState}
      width={"90%"}
    >
      {!isValidate ? (
        <Row style={{ width: "100%" }} justify="start">
          <div
            style={{
              width: "100%",
              margin: "50%",
              marginTop: 0,
              marginBottom: 0,
            }}
          >
            <Spin size="large" />
          </div>
        </Row>
      ) : (
        <Row style={{ width: "100%" }} justify="start">
          <Col
              span={12}
              style={{
                display: "flex",
                marginTop: "10px",
                padding: 5,
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <Title style={{ marginBottom: "1.5px" }} level={5}>
                ¿Tiene moderador?:
              </Title>
              <InputSelect
                name="is_moderador"
                control={control}
                defaultValue={"false"}
                dataOptions={[
                  { title: "Si", value: "true" },
                  { title: "No", value: "false" },
                ]}
                label="Estado"
              />
          </Col>
          <Col
          span={12}
          style={{
            display: "flex",
            marginTop: "10px",
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <Title style={{ marginBottom: "1.5px" }} level={5}>
            Moderador:
          </Title>
          <InputText
            placeHolder="John Doe"
            name="moderador"
            control={control}
          />
          </Col>
          <Col
            span={12}
            style={{
              display: "flex",
              marginTop: "10px",
              padding: 5,
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              Fecha de inicio:
            </Title>
            <InputData
              dateFormat="YYYY-MM-DD HH:mm:ss"
              placeHolder="29/10/2022 00:00:00"
              name="start_at"
              control={control}
            />
          </Col>
          <Col
            span={12}
            style={{
              display: "flex",
              marginTop: "10px",
              padding: 5,
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              Fecha de finalización:
            </Title>
            <InputData
              dateFormat="YYYY-MM-DD HH:mm:ss"
              placeHolder="29/10/2022 00:00:00"
              name="end_at"
              control={control}
            />
          </Col>
          <Col
            span={24}
            style={{
              display: "flex",
              marginTop: "10px",
              padding: 5,
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              Detalles del conferencista:
            </Title>
            {isUpdateEventScheduleTopic ? (
              <InputHTML
                placeHolder="El conferencista es un hábil para..."
                name="speaker_details"
                control={control}
                isUpdating
              />
            ) : (
              <InputHTML
                placeHolder="El conferencista es un hábil para..."
                name="speaker_details"
                control={control}
              />
            )}
          </Col>
          <Col
            span={24}
            style={{
              display: "flex",
              marginTop: "10px",
              padding: 5,
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              Tema:
            </Title>
            {isUpdateEventScheduleTopic ? (
              <InputHTML
                placeHolder="SCP..."
                name="topic"
                control={control}
                isUpdating
              />
            ) : (
              <InputHTML placeHolder="SCP..." name="topic" control={control} />
            )}
          </Col>
          <Button
            style={{
              width: "100%",
              maxWidth: "300px",
              marginTop: 10,
            }}
            type="primary"
            shape="round"
            size="large"
            onClick={onSubmit(onSubmitCreateOrUpdate)}
            loading={isLoading}
          >
            {isUpdateEventScheduleTopic
              ? "Actualizar información"
              : "Crear tema del programa académico"}
          </Button>
        </Row>
      )}
    </WrapperModal>
  );
};
