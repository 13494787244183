import { message, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import { useEventsSponsors } from "./useEventsSponsors";
import {
  deleteEventSponsor,
  eventSponsorChangeModalState,
  refreshTableEventSponsor,
  setEventSponsorForUpdate,
  setIsEventSponsorForUpdate,
} from "../../../../redux";

const { confirm } = Modal;

export const useTableEventsSponsors = () => {
  const dispatch = useAppDispatch();
  const { tableProps } = useEventsSponsors();

  const changeEventSponsorForUpdate = (item: any) => {
    dispatch(setIsEventSponsorForUpdate(true));
    dispatch(setEventSponsorForUpdate(item));
    dispatch(eventSponsorChangeModalState());
  };

  const showDeleteConfirm = (valId: number, val: string) => {
    confirm({
      title: `Estás seguro de borrar el sponsor con el id: ${valId}`,
      icon: <ExclamationCircleOutlined />,
      content: `Una vez ejecutada esta acción no hay vuelta atrás, estás por borrar a "${val}", si deseas continuar dale click a "Borrar sponsor" en caso tal no quieras, solo oprime en "Cancelar".`,
      okText: "Borrar sponsor",
      okType: "danger",
      cancelText: "Cancelar",
      keyboard: true,
      maskClosable: true,
      async onOk() {
        await deleteEventSponsor(valId)
          .then(() => {
            dispatch(refreshTableEventSponsor(true));
            message.success(`Se ha borrado el sponsor con id:${valId}`);
            tableProps?.refresh();
          })
          .catch((err) => {
            console.log({ err });
            message.error(err.message);
          });
      },
      onCancel() {},
    });
  };
  return {
    //state
    //methods
    //functions
    changeEventSponsorForUpdate,
    showDeleteConfirm,
  };
};
