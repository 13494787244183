import { Button, Col, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";

import { useEventsSchedules } from "../../hooks";
import { TableEventsSchedules } from "./TableEventsSchedule";
import { ModalEventSchedule } from "../../../../shared/components";
import { FormProvider } from "react-hook-form";
import { selectModalEventSchedule } from "../../../../redux";

export const EventsSchedules = () => {
  const modalEventSchedule = useSelector(selectModalEventSchedule);
  const {
    navigateEventScheduleCreate,
    isUpdateEventSchedule,
    formMethodsCreate,
    formMethodsUpdate,
  } = useEventsSchedules();
  return (
    <>
      <Row
        gutter={[8, 8]}
        style={{
          padding: "10px",
          width: "100%",
        }}
      >
        <Col
          span={24}
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            type="primary"
            shape="round"
            icon={<PlusOutlined />}
            size="large"
            onClick={navigateEventScheduleCreate}
          >
            Agregar nuevo programa académico
          </Button>
        </Col>
      </Row>
      <TableEventsSchedules />
      {modalEventSchedule && isUpdateEventSchedule && (
        <FormProvider {...formMethodsUpdate}>
          <ModalEventSchedule />
        </FormProvider>
      )}
      {modalEventSchedule && !isUpdateEventSchedule && (
        <FormProvider {...formMethodsCreate}>
          <ModalEventSchedule />
        </FormProvider>
      )}
    </>
  );
};
