import { Spin } from 'antd';
import { AdminMenuLayout } from '../../../shared/components';
import { useClinicalCases } from '../hooks';
import { useClinicalCasesById } from '../hooks/useClinicalCasesById';
import { WrapperClinicalCaseById } from './styled';
import { FormProvider } from 'react-hook-form';
import { ClinicalCasesForm } from './ClinicalCasesForm';

export const ClinicalCasesById = () => {
    const { isUpdateClinicalCase, formMethodsCreate, formMethodsUpdate } =
    useClinicalCases();
    // const dataUpdateClinicalCase = useSelector(
    //     selectClinicalCaseForUpdate
    //   );
  const { isValidate } = useClinicalCasesById();
  // console.log({dataUpdateClinicalCase});
  return (
    <AdminMenuLayout pageDescription="ClinicalCasesById" title="ClinicalCasesById">
      <WrapperClinicalCaseById>
        {!isValidate ? (
          <Spin size="large" />
        ) : (
          <>
            {!isUpdateClinicalCase && (
              <FormProvider {...formMethodsCreate}>
                <ClinicalCasesForm />
              </FormProvider>
            )}
            {isUpdateClinicalCase && (
              <FormProvider {...formMethodsUpdate}>
                <ClinicalCasesForm />
              </FormProvider>
            )}
          </>
        )}
      </WrapperClinicalCaseById>
    </AdminMenuLayout>
  )
}
