import { Button, Col, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";

import { useEventsInscriptions } from "../../hooks";
import { TableEventsInscriptions } from "./TableEventsInscriptions";
import { ModalEventInscription } from "../../../../shared/components";
import { FormProvider } from "react-hook-form";
import { selectModalEventInscription } from "../../../../redux";

export const EventsInscriptions = () => {
  const modalEventInscription = useSelector(selectModalEventInscription);
  const {
    navigateEventInscriptionCreate,
    isUpdateEventInscription,
    formMethodsCreate,
    formMethodsUpdate,
  } = useEventsInscriptions();
  return (
    <>
      <Row
        gutter={[8, 8]}
        style={{
          padding: "10px",
          width: "100%",
        }}
      >
        <Col
          span={24}
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            type="primary"
            shape="round"
            icon={<PlusOutlined />}
            size="large"
            onClick={navigateEventInscriptionCreate}
          >
            Agregar nueva inscripción
          </Button>
        </Col>
      </Row>
      <TableEventsInscriptions />
      {modalEventInscription && isUpdateEventInscription && (
        <FormProvider {...formMethodsUpdate}>
          <ModalEventInscription />
        </FormProvider>
      )}
      {modalEventInscription && !isUpdateEventInscription && (
        <FormProvider {...formMethodsCreate}>
          <ModalEventInscription />
        </FormProvider>
      )}
    </>
  );
};
