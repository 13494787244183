import * as yup from 'yup'

export const validateCreateEventSchedule = yup.object().shape({
    moderator: yup
    .string()
    // .required('Campo requerido.')
    .nullable(true)
    .min(2, 'El MODERADOR debe tener más de 2 caracteres'),
    date:yup
    .string()
    .required('Campo requerido.')
    .nullable(true)
    .min(1, 'La FECHA debe tener más de 1 caracteres'),
})
