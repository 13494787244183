import { message } from 'antd';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { validateCreateUsers, validateUpdateUsers } from '../../../helpers';
import { createUser, updateUser,selectIsUpdateUser, selectUserForUpdate, setIsUserForUpdate, userChangeModalState, selectUserRefreshTable, refreshTableUsers } from '../../../redux';
import { IUserResFind } from '../../../redux/@types';
import useAppDispatch from '../../../redux/hooks/useAppDispatch';
import { useSelector } from 'react-redux';
import { TableProperties } from '../../../shared/components/ui/Table/types';

export const useUsers = () => {
  const dispatch = useAppDispatch();
  const isUpdateUser = useSelector(selectIsUpdateUser);
  const dataUpdateUser = useSelector(selectUserForUpdate);
  const isRefreshTableUsers = useSelector(selectUserRefreshTable)
  const [isLoading, setIsLoading] = useState(false);
  const [tableProps, setTableProps] = useState<TableProperties>();

  const changeModalUserState = () => {
    dispatch(setIsUserForUpdate(false))
    dispatch(userChangeModalState())
  }
  const formMethodsCreate = useForm<IUserResFind>({
    resolver: yupResolver(validateCreateUsers),
  })

  const formMethodsUpdate  = useForm<IUserResFind>({
    resolver: yupResolver(validateUpdateUsers),
  })

  useEffect(() => {
    if (isUpdateUser) {
        formMethodsCreate.reset()
        formMethodsUpdate.reset({ ...dataUpdateUser })
    }

    return () => {
        formMethodsUpdate.reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [isUpdateUser, dataUpdateUser])

  const onSubmitCreateOrUpdate = async(data: IUserResFind) => {
    if (isLoading) return;
    setIsLoading(true);
    if(isUpdateUser){
      // console.log('Actualiando===>',data)
      await updateUser({
        id: data.id,
        email: data.email,
        name: data.name,
        last_name: data.last_name,
        role: data.role,
        status: data.status,
        password: data?.password!
      })
      .then((res:Partial<IUserResFind>)=>{
        setIsLoading(false)
        // dispatch(mappingUpdatedUser(res))
        if(res.id){
          message.success(`Se ha actualizado con éxito el usuario con id:${res.id}`)
          dispatch(refreshTableUsers(true))
          // dispatch(userChangeModalState())
        }
        else{
          message.error(res as string)
        }
      })
    }else{
      // console.log('Creando===>',data)
      await createUser(data)
      .then((res: IUserResFind)=>{
        setIsLoading(false)
        // dispatch(pushCreateUser(res))
        if(res.id){
          message.success(`Se ha creado con éxito el usuario con id:${res.id}`)
          dispatch(refreshTableUsers(true))
          dispatch(userChangeModalState())
        }
        else{
          message.error(res as any)
        }
      })
      .catch((err: any) =>{
        setIsLoading(false)
        console.log(err)
        message.error(err.message)
      })
    }
  }

  useEffect(() => {
    if (isRefreshTableUsers) {
      tableProps?.refresh()
      dispatch(refreshTableUsers(false))
    }
  }, [isRefreshTableUsers])


  return {
    //state
    isUpdateUser,
    isLoading,
    tableProps,
    //methods
    formMethodsCreate,
    formMethodsUpdate,
    setTableProps,
    //functions
    onSubmitCreateOrUpdate,
    changeModalUserState,
  }
}
