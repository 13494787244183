import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useForm } from 'react-hook-form';
import { message } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';

import useAppDispatch from "../../../redux/hooks/useAppDispatch";
import { TableProperties } from "../../../shared/components/ui/Table/types";
import { selectClinicalCaseForUpdate, selectClinicalCaseRefreshTable, selectIsUpdateClinicalCase } from "../../../redux";
import { setIsClinicalCaseForUpdate, refreshTableClinicalCase, updateClinicalCase, createClinicalCase } from '../../../redux/actions/clinical_cases.actions';
import { IClinicalCaseSingle } from '../../../redux/@types/clinical_cases';
import { validateCreateClinicalCases, validateUpdateClinicalCases } from "../../../helpers";


export const useClinicalCases = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
//   const testingX = useHistory();
  const locationPath = useLocation()
  const isUpdateClinicalCase = useSelector(
    selectIsUpdateClinicalCase
  );
  const dataUpdateClinicalCase = useSelector(
    selectClinicalCaseForUpdate
  );
  const isRefreshTableClinicalCases = useSelector(
    selectClinicalCaseRefreshTable
  );

  const [isLoading, setIsLoading] = useState(false);
  const [tableProps, setTableProps] = useState<TableProperties>();

  const navigateClinicalCaseCreate = () => {
    dispatch(setIsClinicalCaseForUpdate(false));
    navigate(`/clinicalCases/${locationPath.pathname.split("/")[2]}/new`);
  };

  const formMethodsCreate = useForm<IClinicalCaseSingle>({
    resolver: yupResolver(validateCreateClinicalCases),
  });

  const formMethodsUpdate = useForm<IClinicalCaseSingle>({
    resolver: yupResolver(validateUpdateClinicalCases),
  });

useEffect(() => {
    if (isUpdateClinicalCase) {
      formMethodsCreate.reset();
      formMethodsUpdate.reset({ ...dataUpdateClinicalCase });
    }

    return () => {
      formMethodsUpdate.reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateClinicalCase, dataUpdateClinicalCase]);

  const updateStatus = async(value: "active" | "inactive", id: any) =>{
    if (isLoading) return;
    setIsLoading(true);
    await updateClinicalCase({
      id,
      status: value,
    })
    .then((res: Partial<IClinicalCaseSingle>) => {
      setIsLoading(false);
      if (res.id) {
        // dispatch(bannersChangeModalState());
        message.success(
          `Se ha actualizado con éxito el estado del producto con id:${res.id}`
        );
        dispatch(refreshTableClinicalCase(true));
      } else {
        message.error(res as string);
      }
    })
    .catch((err: any) => {
      setIsLoading(false);
      console.log(err);
      message.error(err.message);
    });
  }

const onSubmitCreateOrUpdate = async (data: IClinicalCaseSingle) => {
    //  console.log({ data });
    if (isLoading) return;
    setIsLoading(true);
    if (isUpdateClinicalCase) {
    //console.log("Actualiando===>", data.id_form);
      await updateClinicalCase({
        id:data.id,
        title: data.title,
        age_classification: data.age_classification,
        slug: data.slug,
        natural_and_coming: data.natural_and_coming,
        gender: data.gender,
        name: data.name,
        reason_for_consultation: data.reason_for_consultation,
        html_body: data.html_body,
        client_page: locationPath.pathname.split("/")[2] as string,
        status: data.status,
        cover_image_path: data.cover_image_path,
        featured_image_path: data.featured_image_path,
        id_form: data.id_form,
      })
        .then((res: Partial<IClinicalCaseSingle>) => {
          setIsLoading(false);
          // dispatch(mappingUpdatedUser(res))
          if (res.id) {
            navigate(`/clinicalCases/${locationPath.pathname.split("/")[2]}`);
            message.success(
              `Se ha actualizado con éxito la historia clínica con id:${res.id}`
            );
            dispatch(refreshTableClinicalCase(true));
          } else {
            message.error(res as string);
          }
        })
        .catch((err: any) => {
          setIsLoading(false);
          console.log(err);
          message.error(err.message);
        });
    } else {
      // console.log('Creando===>',data)
      // setIsLoading(false);
      await createClinicalCase({
        title: data.title,
        age_classification: data.age_classification,
        slug: data.slug,
        natural_and_coming: data.natural_and_coming,
        gender: data.gender,
        name: data.name,
        reason_for_consultation: data.reason_for_consultation,
        html_body: data.html_body,
        client_page: locationPath.pathname.split("/")[2] as string,
        status: "inactive",
        cover_image_path: data.cover_image_path,
        featured_image_path: data.featured_image_path,
        id_form: data.id_form,
      })
        .then((res: any) => {
          setIsLoading(false);
          if (res.id) {
            // dispatch(productsChangeModalState());
            navigate(`/clinicalCases/${locationPath.pathname.split("/")[2]}`);
            message.success(
              `Se ha creado con éxito la historia clínica con id:${res.id}`
            );
            dispatch(refreshTableClinicalCase(true));
          } else {
            message.error(res as any);
          }
        })
        .catch((err: any) => {
          setIsLoading(false);
          console.log(err);
          message.error(err.message);
        });
    }
  };

  useEffect(() => {
    if (isRefreshTableClinicalCases) {
      tableProps?.refresh();
      dispatch(refreshTableClinicalCase(false));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefreshTableClinicalCases]);

  return {
    //state
    isUpdateClinicalCase,
    isLoading,
    tableProps,
    locationPath,
    //methods
    formMethodsCreate,
    formMethodsUpdate,
    setTableProps,
    //functions
    navigateClinicalCaseCreate,
    onSubmitCreateOrUpdate,
    updateStatus
  }
}
