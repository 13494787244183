import { Avatar, Button, Col, Typography } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

import { eventsService } from "../../../shared/services";
import { IEventSingle } from "../../../redux/@types/events";
import Table from "../../../shared/components/ui/Table";
import { TableColumns } from "../../../shared/components/ui/Table/types";
import { PATH_S3_BUCKET } from "../../../shared/utils/constants";
import { useEvents, useTableEvents } from "../hooks";
import moment from "moment";

const { Title } = Typography;

export const TableEvents = () => {
  const { setTableProps, updateStatus, isLoading, locationPath } = useEvents();
  const { changeEventForUpdate, showDeleteConfirm } = useTableEvents();
  const columns: TableColumns<IEventSingle> = [
    {
      title: "ID",
      width: 5,
      dataIndex: "id",
      key: "id",
      filterType: "filterByID",
    },
    {
      title: "Título",
      width: 12,
      dataIndex: "title",
      key: "title",
      filterType: "text",
    },
    {
      title: "Fecha de inicio",
      dataIndex: "start_date",
      key: "start_date",
      width: 6,
      render: (_, item) => (
        <Col
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-evenly",
            flexDirection: "row",
          }}
        >
          <Title level={5}>
            {moment(item.start_date).add(5,"h").format("DD/MM/YYYY HH:mm:ss")}
          </Title>
        </Col>
      ),
    },
    {
      title: "Fecha de finalización",
      dataIndex: "end_date",
      key: "end_date",
      width: 6,
      render: (_, item) => (
        <Col
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-evenly",
            flexDirection: "row",
          }}
        >
          <Title level={5}>
            {moment(item.end_date).add(5,"h").format("DD/MM/YYYY HH:mm:ss")}
          </Title>
        </Col>
      ),
    },
    {
      title: "Ruta de la imagen destacada",
      width: 7,
      dataIndex: "featured_image_path",
      key: "featured_image_path",
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              flexDirection: "row",
            }}
          >
            {item.featured_image_path && (
              <Avatar
                size={64}
                src={`${PATH_S3_BUCKET}/${item.featured_image_path}`}
              />
            )}
          </Col>
        );
      },
    },
    {
      title: "Imagen de cobertura",
      width: 7,
      dataIndex: "cover_image_path",
      key: "cover_image_path",
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              flexDirection: "row",
            }}
          >
            {item.cover_image_path && (
              <Avatar
                size={64}
                src={`${PATH_S3_BUCKET}/${item.cover_image_path}`}
              />
            )}
          </Col>
        );
      },
    },
    {
      title: "URL web",
      width: 6,
      dataIndex: "web_url",
      key: "web_url",
    },
    {
      title: "Estado",
      width: 8,
      dataIndex: "status",
      key: "status",
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              flexDirection: "row",
            }}
          >
            <button
              disabled={isLoading}
              onClick={() => {
                if (item.status === "active") {
                  updateStatus("inactive", item.id);
                } else if (item.status === "inactive") {
                  updateStatus("active", item.id);
                }
              }}
              style={{
                display: "flex",
                padding: "5px 27px",
                borderWidth: 0,
                cursor: "pointer",
                borderRadius: "20px",
                backgroundColor:
                  item.status === "active" ? "#4bd542" : "#ff4d4f",
              }}
            >
              <Title
                style={{
                  marginBottom: "1.5px",
                  color: "white",
                }}
                level={5}
              >
                {item.status}
              </Title>
            </button>
          </Col>
        );
      },
      filters: [
        {
          text: "Activo",
          value: "active",
        },
        {
          text: "Inactivo",
          value: "inactive",
        },
      ],
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "right",
      width: 7,
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              flexDirection: "row",
            }}
          >
            <Button
              type="primary"
              shape="circle"
              onClick={() => changeEventForUpdate(item)}
              icon={<EditOutlined />}
              size="large"
            />
            <Button
              type="primary"
              danger
              onClick={() => showDeleteConfirm(item.id, `${item.title}`)}
              shape="circle"
              icon={<DeleteOutlined />}
              size="large"
            />
          </Col>
        );
      },
    },
  ];

  return (
    <Table<IEventSingle>
      columns={columns}
      fetchQueryProps={{
        $sort: { id: -1 },
        type: locationPath.pathname.split("/")[2],
      }}
      service={eventsService}
      onLoad={setTableProps}
    />
  );
};
