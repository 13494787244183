import { Button, Col, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import { AdminMenuLayout } from "../../../shared/components";
import { WrapperCardClinicalCases } from "./styled";
import { useClinicalCases } from "../hooks";
import { TableClinicalCases } from "./TableClinicalCases";

export const ClinicalCases = () => {
  const { navigateClinicalCaseCreate } = useClinicalCases()
  return (
    <AdminMenuLayout pageDescription="ClinicalCases" title="ClinicalCases">
      <WrapperCardClinicalCases>
        <Row
          gutter={[8, 8]}
          style={{
            padding: "10px",
            width: "100%",
          }}
        >
          <Col
            span={24}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              type="primary"
              shape="round"
              icon={<PlusOutlined />}
              size="large"
              onClick={navigateClinicalCaseCreate}
            >
              Agregar nuevo caso clínico
            </Button>
          </Col>
        </Row>
        <TableClinicalCases />
      </WrapperCardClinicalCases>
    </AdminMenuLayout>
  )
}
