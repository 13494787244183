import {useEffect, useState} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { message } from 'antd';

import { eventsService } from '../../../../shared/services';
import useAppDispatch from '../../../../redux/hooks/useAppDispatch';
import { setEventForUpdate, setIsEventForUpdate } from '../../../../redux';


export const useEventsById = () => {
    const [isValidate, setIsValidate ] = useState(false)
    const { pathname } = useLocation()
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const getSingleEvent = async ( id: number) =>{
        return await eventsService
        .get(id)
        .then((res) => {
          return res;
        })
        .catch((err: any) => {
          console.log(err);
          navigate(`/products`);
          message.error(err.message);
        });
      }

      useEffect(() => {
        if( pathname !== "/events/residents/new" && pathname !== "/events/scp/new"){
          if (isNaN(pathname.split("/")[3] as any)) return navigate(`/events/${pathname.split("/")[2]}`);
            dispatch(setIsEventForUpdate(true));
            getSingleEvent(Number(pathname.split("/")[3])).then((res) =>
                dispatch(setEventForUpdate(res as any))
              );
            setIsValidate(true)
        }else if(pathname === "/events/residents/new" || pathname === "/events/scp/new" ){
            dispatch(setIsEventForUpdate(false));
            setIsValidate(true)
        }
    }, [])


    return {
        //state
        isValidate
        //methods
        //functions
    }
}
