import { combineReducers } from '@reduxjs/toolkit'
import authReducer from './auth.reducer'
import clinicalCasesReducer from './clinical_cases.reducer'
import eventsReducer from './events.reducer'
import uiReducer from './ui.reducer'
import userReducer from "./user.reducer"



export default combineReducers({
    users: userReducer,
    auth: authReducer,
    ui: uiReducer,
    clinicalCases: clinicalCasesReducer,
    events: eventsReducer
})