import { Button, Col, Row } from "antd";
import { DownloadOutlined } from "@ant-design/icons";

import { AdminMenuLayout } from "../../../shared/components";
import { WrapperCardLeads } from "./styled";
import { TableLeads } from "./TableLeads";
import { leadsService } from "../../../shared/services";

export const Leads = () => {

  const handleDownload = async () => {
    await leadsService.find({ query: { $client: { exportData: 'true' } } }).then((res) => {
        window.open(res.data[0].objectUrl, '_blank')
    })
}

  return (
    <AdminMenuLayout pageDescription="Leads" title="Leads">
      <WrapperCardLeads>
        <Row
          gutter={[8, 8]}
          style={{
            padding: "10px",
            width: "100%",
          }}
        >
          <Col
            span={24}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              type="primary"
              shape="round"
              icon={<DownloadOutlined />}
              size="large"
              onClick={handleDownload}
            >
              Descargar listado de contactos
            </Button>
          </Col>
            <TableLeads />
        </Row>
      </WrapperCardLeads>
    </AdminMenuLayout>
  )
}
