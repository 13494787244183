import { Button, Col, Row, Typography } from "antd";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";

import { EventsScheduleTopics, useEventsSchedules } from "../../../../modules";
import {
  selectForUpdateEventSchedule,
  selectIsUpdateEventSchedule,
  selectModalEventSchedule,
} from "../../../../redux";
import { IEventScheduleSingle } from "../../../../redux/@types";
import { InputData, InputText } from "../inputs";
import { WrapperModal } from "./styled";

const { Title } = Typography;

export const ModalEventSchedule = () => {
  const modalEventSchedule = useSelector(selectModalEventSchedule);
  const isUpdateEventSchedule = useSelector(selectIsUpdateEventSchedule);
  const updateEventSchedule = useSelector(selectForUpdateEventSchedule);
  const { isLoading, changeModalEventScheduleState, onSubmitCreateOrUpdate } =
    useEventsSchedules();

  const { control, handleSubmit: onSubmit } =
    useFormContext<IEventScheduleSingle>();
  return (
    <WrapperModal
      title={
        isUpdateEventSchedule
          ? `Actualizando programa académico: '${updateEventSchedule?.id!}'`
          : `Creando programa académico`
      }
      open={modalEventSchedule}
      onOk={() => {}}
      onCancel={changeModalEventScheduleState}
      width={"95%"}
    >
      <Row style={{ width: "100%" }} justify="start">
        <Col
          span={8}
          style={{
            display: "flex",
            marginTop: "10px",
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <Title style={{ marginBottom: "1.5px" }} level={5}>
            Moderador:
          </Title>
          <InputText
            placeHolder="John Doe"
            name="moderator"
            control={control}
          />
        </Col>
        <Col
          span={8}
          style={{
            display: "flex",
            marginTop: "10px",
            padding: 5,
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <Title style={{ marginBottom: "1.5px" }} level={5}>
            Fecha:
          </Title>
          <InputData
            dateFormat="YYYY-MM-DD"
            placeHolder="29/10/2022"
            name="date"
            control={control}
          />
        </Col>
        <Col
          span={8}
          style={{
            display: "flex",
            marginTop: "10px",
            padding: 5,
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <Button
            style={{
              width: "100%",
              maxWidth: "300px",
              marginTop: 10,
            }}
            type="primary"
            shape="round"
            size="large"
            onClick={onSubmit(onSubmitCreateOrUpdate)}
            loading={isLoading}
          >
            {isUpdateEventSchedule
              ? "Actualizar información"
              : "Crear programa académico"}
          </Button>
        </Col>
        {isUpdateEventSchedule && (
          <Col
            span={24}
            style={{
              display: "flex",
              marginTop: "10px",
              padding: 5,
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <EventsScheduleTopics
              events_schedule_id={updateEventSchedule?.id!}
            />
          </Col>
        )}
      </Row>
    </WrapperModal>
  );
};
