import { Events } from "../modules"

interface Props {
isSCP: boolean
}

export const EventsPage = ({isSCP}:Props) => {
  return (
    <Events isSCP={isSCP}/>
  )
}
