import { Button, Col, Typography } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

import { IEventScheduleTopicSingle } from "../../../../redux/@types";
import { TableColumns } from "../../../../shared/components/ui/Table/types";
import { eventsScheduleTopicsService } from "../../../../shared/services";
import {
  useEventsScheduleTopics,
  useTableEventsScheduleTopics,
} from "../../hooks";
import Table from "../../../../shared/components/ui/Table";
import moment from "moment";

const { Title } = Typography;

export const TableEventsScheduleTopics = ({
  events_schedule_id,
}: {
  events_schedule_id: number;
}) => {
  const { setTableProps } = useEventsScheduleTopics();
  const { changeEventScheduleTopicForUpdate, showDeleteConfirm } =
    useTableEventsScheduleTopics();

  const columns: TableColumns<IEventScheduleTopicSingle> = [
    {
      title: "ID",
      width: 3,
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Moderador",
      width: 7,
      dataIndex: "moderador",
      key: "moderador",
      filterType: "text",
    },
    {
      title: "Fecha de inicio",
      dataIndex: "start_at",
      key: "start_at",
      width: 6,
      render: (_, item) => (
        <Col
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-evenly",
            flexDirection: "row",
          }}
        >
          <Title level={5}>
            {moment(item.start_at).add(5,"h").format("DD/MM/YYYY HH:mm:ss")}
          </Title>
        </Col>
      ),
    },
    {
      title: "Fecha de finalización",
      dataIndex: "end_at",
      key: "end_at",
      width: 6,
      render: (_, item) => (
        <Col
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-evenly",
            flexDirection: "row",
          }}
        >
          <Title level={5}>
            {moment(item.end_at).add(5,"h").format("DD/MM/YYYY HH:mm:ss")}
          </Title>
        </Col>
      ),
    },
    {
      title: "¿Tiene moderador?",
      width: 4,
      dataIndex: "is_moderador",
      key: "is_moderador",
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              flexDirection: "row",
            }}
          >
            <Col
              style={{
                display: "flex",
                padding: "5px 27px",
                borderWidth: 0,
                cursor: "pointer",
                borderRadius: "20px",
                backgroundColor:
                  item.is_moderador ? "#4bd542" : "#ff4d4f",
              }}
            >
              <Title
                style={{
                  marginBottom: "1.5px",
                  color: "white",
                }}
                level={5}
              >
                {item.is_moderador ? 'Si': 'No'}
              </Title>
            </Col>
          </Col>
        );
      },
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "right",
      width: 3,
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              flexDirection: "row",
            }}
          >
            <Button
              type="primary"
              shape="circle"
              onClick={() => changeEventScheduleTopicForUpdate(item)}
              icon={<EditOutlined />}
              size="large"
            />
            <Button
              type="primary"
              danger
              onClick={() => showDeleteConfirm(item.id, `${item.start_at}`)}
              shape="circle"
              icon={<DeleteOutlined />}
              size="large"
            />
          </Col>
        );
      },
    },
  ];
  return (
    <Table<IEventScheduleTopicSingle>
      columns={columns}
      fetchQueryProps={{
        $sort: { id: -1 },
        events_schedule_id,
      }}
      service={eventsScheduleTopicsService}
      onLoad={setTableProps}
    />
  );
};
