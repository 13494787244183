import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { message } from "antd";
import { useSelector } from "react-redux";

import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import {
  createEventScheduleTopic,
  eventScheduleTopicChangeModalState,
  refreshTableEventScheduleTopic,
  selectForUpdateEventScheduleTopic,
  selectIsUpdateEventScheduleTopic,
  selectRefreshTableEventScheduleTopic,
  setIsEventScheduleTopicForUpdate,
  updateEventScheduleTopic,
} from "../../../../redux";
import { TableProperties } from "../../../../shared/components/ui/Table/types";
import { IEventScheduleTopicSingle } from "../../../../redux/@types";
import {
  validateCreateEventScheduleTopic,
  validateUpdateEventScheduleTopic,
} from "../../../../helpers";

export const useEventsScheduleTopics = () => {
  const dispatch = useAppDispatch();
  const isUpdateEventScheduleTopic = useSelector(
    selectIsUpdateEventScheduleTopic
  );
  const dataUpdateEventScheduleTopic = useSelector(
    selectForUpdateEventScheduleTopic
  );
  const isRefreshTableEventScheduleTopics = useSelector(
    selectRefreshTableEventScheduleTopic
  );

  const [isLoading, setIsLoading] = useState(false);
  const [tableProps, setTableProps] = useState<TableProperties>();

  const navigateEventScheduleTopicCreate = () => {
    dispatch(setIsEventScheduleTopicForUpdate(false));
    dispatch(eventScheduleTopicChangeModalState());
  };

  const changeModalEventScheduleTopicState = () => {
    dispatch(eventScheduleTopicChangeModalState());
  };

  const formMethodsCreate = useForm<IEventScheduleTopicSingle>({
    resolver: yupResolver(validateCreateEventScheduleTopic),
  });

  const formMethodsUpdate = useForm<IEventScheduleTopicSingle>({
    resolver: yupResolver(validateUpdateEventScheduleTopic),
  });

  useEffect(() => {
    if (isUpdateEventScheduleTopic) {
      formMethodsCreate.reset();
      formMethodsUpdate.reset({ ...dataUpdateEventScheduleTopic });
    }

    return () => {
      formMethodsUpdate.reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateEventScheduleTopic, dataUpdateEventScheduleTopic]);

  const onSubmitCreateOrUpdate = async (data: IEventScheduleTopicSingle) => {
    //console.log({ data });
    if (isLoading) return;
    setIsLoading(true);
    if (isUpdateEventScheduleTopic) {
      // console.log("Actualizando===>", data);
      await updateEventScheduleTopic({
        id: data.id,
        start_at: data.start_at,
        end_at: data.end_at,
        speaker_details: data.speaker_details,
        topic: data.topic,
        is_moderador: data.is_moderador,
        moderador: data.moderador,
      })
        .then((res: Partial<IEventScheduleTopicSingle>) => {
          setIsLoading(false);
          // dispatch(mappingUpdatedUser(res))
          if (res.id) {
            // navigate(`/event/${locationPath.pathname.split("/")[2]}`);
            message.success(
              `Se ha actualizado con éxito el tema del programa académico con id:${res.id}`
            );
            dispatch(refreshTableEventScheduleTopic(true));
          } else {
            message.error(res as string);
          }
        })
        .catch((err: any) => {
          setIsLoading(false);
          console.log(err);
          message.error(err.message);
        });
    } else {
      // console.log('Creando===>',data)
      // setIsLoading(false);
      await createEventScheduleTopic({
        events_schedule_id: data?.events_schedule_id!,
        start_at: data.start_at,
        end_at: data.end_at,
        speaker_details: data.speaker_details,
        topic: data.topic,
        is_moderador:  data.is_moderador || false,
        moderador: data.moderador,
      })
        .then((res: any) => {
          setIsLoading(false);
          if (res.id) {
            dispatch(eventScheduleTopicChangeModalState());
            // navigate(`/event/${locationPath.pathname.split("/")[2]}`);
            message.success(
              `Se ha creado con éxito el tema del programa académico con id:${res.id}`
            );
            dispatch(refreshTableEventScheduleTopic(true));
          } else {
            message.error(res as any);
          }
        })
        .catch((err: any) => {
          setIsLoading(false);
          console.log(err);
          message.error(err.message);
        });
    }
  };

  useEffect(() => {
    if (isRefreshTableEventScheduleTopics) {
      tableProps?.refresh();
      dispatch(refreshTableEventScheduleTopic(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefreshTableEventScheduleTopics]);

  return {
    //state
    isUpdateEventScheduleTopic,
    isLoading,
    tableProps,
    //methods
    formMethodsCreate,
    formMethodsUpdate,
    setTableProps,
    //functions
    navigateEventScheduleTopicCreate,
    onSubmitCreateOrUpdate,
    changeModalEventScheduleTopicState,
  };
};
