import { Col, Typography } from "antd";

import Table from "../../../shared/components/ui/Table"
import { TableColumns } from "../../../shared/components/ui/Table/types";
import { ninjaFormsWebhookService } from "../../../shared/services"

const { Title } = Typography;

interface Props {
    isSCP: boolean
}

interface INinjaFormsWebhookSingle {
    id: number;
    clinical_case_id: number;
    clinical_case_type: "scp" | "residents";
    cc_email: string;
    form_json: string;
}

export const TableNinjaFormsWebhook = ({isSCP}:Props) => {

    const columns: TableColumns<INinjaFormsWebhookSingle> = [
        {
            title: "ID",
            width: 5,
            dataIndex: "id",
            key: "id",
        },
        {
            title: "ID de Formulario",
            width: 5,
            dataIndex: "form_id",
            key: "form_id",
        },
        {
            title: "CC_email",
            width: 5,
            dataIndex: "cc_email",
            key: "cc_email",
        }
    ]

  return (
    <Table<INinjaFormsWebhookSingle>
            columns={columns}
            scroll={{ x: 'max-content' }}
            fetchQueryProps={{
                $sort: { id: -1 },
                clinical_case_type: isSCP ? "scp" : "residents"
            }}
            service={ninjaFormsWebhookService}
            // onLoad={setTableProps}
            />
  )
}
