import { Button, Col, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";

import { useEventsScheduleTopics } from "../../hooks";
import { TableEventsScheduleTopics } from "./TableEventScheduleTopic";
import { ModalEventScheduleTopic } from "../../../../shared/components";
import { FormProvider } from "react-hook-form";
import { selectModalEventScheduleTopic } from "../../../../redux";

export const EventsScheduleTopics = ({
  events_schedule_id,
}: {
  events_schedule_id: number;
}) => {
  const modalEventScheduleTopic = useSelector(selectModalEventScheduleTopic);
  const {
    navigateEventScheduleTopicCreate,
    isUpdateEventScheduleTopic,
    formMethodsCreate,
    formMethodsUpdate,
  } = useEventsScheduleTopics();
  return (
    <>
      <Row
        gutter={[8, 8]}
        style={{
          padding: "10px",
          width: "100%",
        }}
      >
        <Col
          span={24}
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            type="primary"
            shape="round"
            icon={<PlusOutlined />}
            size="large"
            onClick={navigateEventScheduleTopicCreate}
          >
            Agregar nuevo tema del programa académico
          </Button>
        </Col>
      </Row>
      <TableEventsScheduleTopics events_schedule_id={events_schedule_id} />
      {modalEventScheduleTopic && isUpdateEventScheduleTopic && (
        <FormProvider {...formMethodsUpdate}>
          <ModalEventScheduleTopic />
        </FormProvider>
      )}
      {modalEventScheduleTopic && !isUpdateEventScheduleTopic && (
        <FormProvider {...formMethodsCreate}>
          <ModalEventScheduleTopic events_schedule_id={events_schedule_id} />
        </FormProvider>
      )}
    </>
  );
};
