import {useEffect, useState} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { message } from 'antd';

import useAppDispatch from '../../../redux/hooks/useAppDispatch';
import { clinicalCasesService } from '../../../shared/services';
import { setClinicalCaseForUpdate, setIsClinicalCaseForUpdate } from '../../../redux/actions/clinical_cases.actions';


export const useClinicalCasesById = () => {
    const [isValidate, setIsValidate ] = useState(false)
    const { pathname } = useLocation()
    // const dataUpdateClinicalCases = useSelector(selectClinicalCaseForUpdate);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const getSingleClinicalCase = async ( id: number) =>{
        return await clinicalCasesService
        .get(id)
        .then((res) => {
          return res;
        })
        .catch((err: any) => {
          console.log(err);
          navigate(`/products`);
          message.error(err.message);
        });
      }

      useEffect(() => {
        if( pathname !== "/clinicalCases/RESIDENTES/new" && pathname !== "/clinicalCases/SCP/new"){
          if (isNaN(pathname.split("/")[3] as any)) return navigate(`/clinicalCases/${pathname.split("/")[2]}`);
            dispatch(setIsClinicalCaseForUpdate(true));
            getSingleClinicalCase(Number(pathname.split("/")[3])).then((res) =>
                dispatch(setClinicalCaseForUpdate(res as any))
              );
            setIsValidate(true)
        }else if(pathname === "/clinicalCases/RESIDENTES/new" || pathname === "/clinicalCases/SCP/new" ){
            dispatch(setIsClinicalCaseForUpdate(false));
            setIsValidate(true)
        }
    }, [])


    return {
        //state
        isValidate
        //methods
        //functions
      }
}
