import * as yup from 'yup'

export const validateUpdateClinicalCases = yup.object().shape({
    title: yup
    .string()
    .min(2, 'El Título debe tener más de 2 caracteres'),
    id_form: yup
    .number()
    .nullable(true)
    ,
    age_classification: yup
    .string()
    .min(1, 'LA CLASIFICACIÓN DE EDAD de edad debe tener mínimo de 1 caracteres'),
    slug: yup
    .string()
    .min(2, 'El SLUG debe tener más de 2 caracteres'),
    natural_and_coming: yup
    .string()
    .nullable(true),
    gender: yup
    .string()
    .oneOf(['M','F'], 'Debes elegir entre estas opciones'),
    name: yup
    .string()
    .nullable(true),
    reason_for_consultation: yup
    .string()
    .nullable(true),
    html_body: yup
    .string()
    .min(2, 'EL CUERPO DEL MENSAJE debe tener más de 2 caracteres'),
    // client_page: yup
    // .string()
    // .required('Campo requerido.')
    // .oneOf(['scp', 'residents'], 'Debes elegir entre estas opciones'),
    status: yup
    .string()
    .required('Campo requerido.')
    .oneOf(['active', 'inactive'], 'Debes elegir entre estas opciones'),
})
