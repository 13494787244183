import { IClinicalCaseSingle, IClinicalCaseState } from "../@types"
import { clinicalCasesActions } from "../action-types";


type userActionType =
| { type: typeof clinicalCasesActions.CLINICAL_CASES_CHANGE_MODAL_STATE }
| { type: typeof clinicalCasesActions.GET_CLINICAL_CASES_FOR_UPDATE; payload: IClinicalCaseSingle}
| { type: typeof clinicalCasesActions.IS_CLINICAL_CASE_FOR_UPDATE; payload: boolean}
| { type: typeof clinicalCasesActions.SWITCH_CLINICAL_CASES_REFRESH_TABLE; payload: boolean}

const INITIAL_STATE: IClinicalCaseState = {
    clinicalCaseForUpdate: undefined,
    isUpdateClinicalCase: false,
    clinicalCaseModalIsOpen: false,
    refreshTableClinicalCases: false,
}
export default function clinicalCasesReducer(state: IClinicalCaseState = INITIAL_STATE, action: userActionType ): IClinicalCaseState{
    switch ( action.type) {
        case clinicalCasesActions.CLINICAL_CASES_CHANGE_MODAL_STATE:
            return {
                ...state,
                clinicalCaseModalIsOpen: !state.clinicalCaseModalIsOpen,
            }
        case clinicalCasesActions.GET_CLINICAL_CASES_FOR_UPDATE:
            return {
                ...state,
                clinicalCaseForUpdate: action.payload
            }
        case clinicalCasesActions.IS_CLINICAL_CASE_FOR_UPDATE:
            return {
                ...state,
                isUpdateClinicalCase: action.payload
            }
        case clinicalCasesActions.SWITCH_CLINICAL_CASES_REFRESH_TABLE:
            return {
                ...state,
                refreshTableClinicalCases: action.payload
            }
        default:
            return state
    }
}