import * as authService from './authentication.service'
import { getService } from '../../clients/feathers.clients';
import Service from './service.class'

//authenticate
export const authenticationService = authService
//services
export const usersService = new Service<any>(getService('users'))
export const clinicalCasesService = new Service<any>(getService('clinical-cases'))
export const leadsService = new Service<any>(getService('leads'))
export const requestsOfDataUpdateService = new Service<any>(getService('requests-of-data-update'))
export const eventsService = new Service<any>(getService('events'))
export const eventsSpeakersService = new Service<any>(getService('events-speakers'))
export const eventsScheduleService = new Service<any>(getService('events-schedule'))
export const eventsScheduleTopicsService = new Service<any>(getService('events-schedule-topics'))
export const eventsSponsorsService = new Service<any>(getService('events-sponsors'))
export const eventsInscriptionsService = new Service<any>(getService('events-inscriptions'))
export const ninjaFormsWebhookService = new Service<any>(getService('ninja-forms-webhook'))
//s3 SERVICES
export const s3FileUploadService = new Service(getService('s3Client'))
// export const usersService = app.service('users')
