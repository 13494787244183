import { IClinicalCaseSingle } from "../@types"

export const  CLINICAL_CASES_CHANGE_MODAL_STATE = '[ CLINICAL CASES ACTIONS ] Clinical Cases Change Modal State'

export interface IClinicalCaseChangeModalState {
    type: typeof CLINICAL_CASES_CHANGE_MODAL_STATE
}

export const GET_CLINICAL_CASES_FOR_UPDATE = '[ CLINICAL CASES ACTIONS ] Get Clinical Cases For Update'

export interface IGetClinicalCaseForUpdate {
    type: typeof GET_CLINICAL_CASES_FOR_UPDATE
    payload: IClinicalCaseSingle
}

export const IS_CLINICAL_CASE_FOR_UPDATE = '[ CLINICAL CASES ACTIONS ] Is Clinical Cases For Update'

export interface IIsClinicalCaseForUpdate {
    type: typeof IS_CLINICAL_CASE_FOR_UPDATE
    payload: boolean
}

export const SWITCH_CLINICAL_CASES_REFRESH_TABLE = '[ CLINICAL CASES ACTIONS ] Switch Clinical Cases Refresh Table'

export interface ISwitchClinicalCasesRefreshTable {
    type: typeof SWITCH_CLINICAL_CASES_REFRESH_TABLE
    payload: boolean
}