import { message, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import useAppDispatch from "../../../redux/hooks/useAppDispatch";
import { useClinicalCases } from "./useClinicalCases";
import { deleteClinicalCase, refreshTableClinicalCase, setIsClinicalCaseForUpdate } from '../../../redux/actions/clinical_cases.actions';
;

const { confirm } = Modal;

export const useTableClinicalCases = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const locationPath = useLocation()
  const { tableProps } = useClinicalCases();

  const changeProductsForUpdate = (item: any) => {
    dispatch(setIsClinicalCaseForUpdate(true));
    navigate(`/clinicalCases/${locationPath.pathname.split("/")[2]}/${item.id}`);
  };

  const showDeleteConfirm = (valId: number, val: string) => {
    confirm({
      title: `Estás seguro de borrar el caso clínico con el id: ${valId}`,
      icon: <ExclamationCircleOutlined />,
      content: `Una vez ejecutada esta acción no hay vuelta atrás, estás por borrar a "${val}", si deseas continuar dale click a "Borrar caso clínico" en caso tal no quieras, solo oprime en "Cancelar".`,
      okText: "Borrar caso clínico",
      okType: "danger",
      cancelText: "Cancelar",
      keyboard: true,
      maskClosable: true,
      async onOk() {
        await deleteClinicalCase(valId)
          .then(() => {
            dispatch(refreshTableClinicalCase(true));
            message.success(`Se ha borrado el caso clínico con id:${valId}`);
            tableProps?.refresh();
          })
          .catch((err) => {
            console.log({ err });
            message.error(err.message);
          });
      },
      onCancel() {},
    });
  };

  return {
    //state
    //methods
    //functions
    changeProductsForUpdate,
    showDeleteConfirm,
  };
};
