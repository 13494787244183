import { message, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import { useEventsScheduleTopics } from "./useEventScheduleTopic";
import {
  deleteEventScheduleTopic,
  eventScheduleTopicChangeModalState,
  refreshTableEventScheduleTopic,
  setEventScheduleTopicForUpdate,
  setIsEventScheduleTopicForUpdate,
} from "../../../../redux";

const { confirm } = Modal;

export const useTableEventsScheduleTopics = () => {
  const dispatch = useAppDispatch();
  const { tableProps } = useEventsScheduleTopics();

  const changeEventScheduleTopicForUpdate = (item: any) => {
    dispatch(setIsEventScheduleTopicForUpdate(true));
    dispatch(setEventScheduleTopicForUpdate({...item, is_moderador: item?.is_moderador! === 0 ? 'false' : 'true'}));
    dispatch(eventScheduleTopicChangeModalState());
  };

  const showDeleteConfirm = (valId: number, val: string) => {
    confirm({
      title: `Estás seguro de borrar el  tema del programa académico con el id: ${valId}`,
      icon: <ExclamationCircleOutlined />,
      content: `Una vez ejecutada esta acción no hay vuelta atrás, estás por borrar a "${val}", si deseas continuar dale click a "Borrar el tema del programa académico" en caso tal no quieras, solo oprime en "Cancelar".`,
      okText: "Borrar el tema del programa académico",
      okType: "danger",
      cancelText: "Cancelar",
      keyboard: true,
      maskClosable: true,
      async onOk() {
        await deleteEventScheduleTopic(valId)
          .then(() => {
            dispatch(refreshTableEventScheduleTopic(true));
            message.success(
              `Se ha borrado el  tema del programa académico con id:${valId}`
            );
            tableProps?.refresh();
          })
          .catch((err) => {
            console.log({ err });
            message.error(err.message);
          });
      },
      onCancel() {},
    });
  };
  return {
    //state
    //methods
    //functions
    changeEventScheduleTopicForUpdate,
    showDeleteConfirm,
  };
};
