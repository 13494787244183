import { Button, Col, Typography } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

import { IEventScheduleSingle } from "../../../../redux/@types";
import { TableColumns } from "../../../../shared/components/ui/Table/types";
import { eventsScheduleService } from "../../../../shared/services";
import { useEventsSchedules, useTableEventsSchedules } from "../../hooks";
import Table from "../../../../shared/components/ui/Table";
import moment from "moment";

const { Title } = Typography;

export const TableEventsSchedules = () => {
  const { setTableProps, idForSchedule } = useEventsSchedules();
  const { changeEventScheduleForUpdate, showDeleteConfirm } =
    useTableEventsSchedules();

  const columns: TableColumns<IEventScheduleSingle> = [
    {
      title: "ID",
      width: 3,
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Moderador",
      width: 8,
      dataIndex: "moderator",
      key: "moderator",
      filterType: "text",
    },
    {
      title: "Fecha",
      dataIndex: "date",
      key: "date",
      width: 6,
      render: (_, item) => (
        <Col
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-evenly",
            flexDirection: "row",
          }}
        >
          <Title level={5}>{moment(item.date).zone(0).format("DD/MM/YYYY")}</Title>
        </Col>
      ),
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "right",
      width: 3,
      render: (_, item) => {
        // console.log({item})
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              flexDirection: "row",
            }}
          >
            <Button
              type="primary"
              shape="circle"
              onClick={() => changeEventScheduleForUpdate(item)}
              icon={<EditOutlined />}
              size="large"
            />
            <Button
              type="primary"
              danger
              onClick={() => showDeleteConfirm(item.id, `${item.moderator}`)}
              shape="circle"
              icon={<DeleteOutlined />}
              size="large"
            />
          </Col>
        );
      },
    },
  ];
  return (
    <Table<IEventScheduleSingle>
      columns={columns}
      fetchQueryProps={{
        $sort: { id: -1 },
        events_id: idForSchedule,
      }}
      service={eventsScheduleService}
      onLoad={setTableProps}
    />
  );
};
