import * as yup from 'yup'

export const validateCreateEventInscription = yup.object().shape({
    title: yup
    .string()
    .required('Campo requerido.')
    .min(2, 'El Título debe tener más de 2 caracteres'),
    price: yup
    .string()
    .min(2, 'El Precio debe tener más de 2 caracteres'),
    html_body: yup
    .string()
    .required('Campo requerido.')
    .min(2, 'EL CUERPO DEL MENSAJE debe tener más de 2 caracteres'),
    external_url: yup
    .string()
    .required('Campo requerido.')
    .min(2, 'LA URL EXTERNA debe tener más de 2 caracteres'),
    priority: yup
    .number(),
    // status: yup
    // .string()
    // .required('Campo requerido.')
    // .oneOf(['active', 'inactive'], 'Debes elegir entre estas opciones'),
})