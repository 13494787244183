import { Button, Col, Image, message, Row, Typography } from "antd";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";

import { selectIsUpdateClinicalCase } from "../../../redux";
import { IClinicalCaseSingle } from "../../../redux/@types";
import { InputHTML, InputSelect, InputText } from "../../../shared/components";
import Upload from "../../../shared/components/ui/inputs/Upload";
import { PATH_S3_BUCKET } from "../../../shared/utils/constants";
import { useClinicalCases } from "../hooks";

const { Title } = Typography;

export const ClinicalCasesForm = () => {

    const isUpdateClinicalCase = useSelector(selectIsUpdateClinicalCase);

    const { isLoading, onSubmitCreateOrUpdate } = useClinicalCases();

    const { control, handleSubmit: onSubmit, setValue, watch } = useFormContext<IClinicalCaseSingle>();
    let cover_image_path = watch("cover_image_path");
    let featured_image_path = watch("featured_image_path");
    const [isValidate, setIsValidate] = useState(false);
    useEffect(() => {
        setTimeout(() => {
        setIsValidate(!isValidate);
        }, 1500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  return (
    <>
    {isValidate && (
        <Row justify="start">
            <Col
            span={12}
            style={{
              display: "flex",
              marginTop: "10px",
              padding: 5,
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              Título:
            </Title>
            <InputText
              placeHolder="Caso 1"
              name="title"
              control={control}
            />
          </Col>
          <Col
            span={12}
            style={{
              display: "flex",
              marginTop: "10px",
              padding: 5,
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              Clasificación de edades:
            </Title>
            <InputText
              placeHolder="de 1- 5 años"
              name="age_classification"
              control={control}
            />
          </Col>
          <Col
            span={12}
            style={{
              display: "flex",
              marginTop: "10px",
              padding: 5,
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              Slug:
            </Title>
            <InputText
              placeHolder="123ABC"
              name="slug"
              control={control}
            />
          </Col>
          <Col
            span={12}
            style={{
              display: "flex",
              marginTop: "10px",
              padding: 5,
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Title style={{ marginBottom: "1.5px" }} level={5}>
                Natural y venidera/o:
            </Title>
            <InputText
              placeHolder="123ABC"
              name="natural_and_coming"
              control={control}
            />
          </Col>
          {isUpdateClinicalCase && (
              <Col
              span={12}
              style={{
                display: "flex",
                marginTop: "10px",
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <Title style={{ marginBottom: "1.5px" }} level={5}>
                Imagen de cobertura:
              </Title>
              {cover_image_path ? (
                <img
                  src={`${PATH_S3_BUCKET}/${cover_image_path}`}
                  alt="cover_image_path"
                  style={{
                    marginBottom: "7px",
                    width: "100%",
                    height: "100%",
                    maxWidth: "250px",
                    maxHeight: "250px",
                  }}
                />
              ) : (
                <img
                  src={`https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/330px-No-Image-Placeholder.svg.png?20200912122019`}
                  alt="cover_image_path_not"
                  style={{
                    marginBottom: "7px",
                    width: "100%",
                    height: "100%",
                    maxWidth: "250px",
                    maxHeight: "250px",
                  }}
                />
              )}
              <Upload
                filePath="cover_image_path"
                onUploadedFile={(files) => {
                  // console.log({ files });
                  const file = files[0];
                  setValue("cover_image_path", file.fileKey);
                  message.success("Se ha actualizado la imagen");
                }}
              />
            </Col>
          )}
          {isUpdateClinicalCase && (
            <Col
              span={12}
              style={{
                display: "flex",
                marginTop: "10px",
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <Title style={{ marginBottom: "1.5px" }} level={5}>
              Ruta de la imagen destacada:
              </Title>
              {featured_image_path ? (
                <img
                  src={`${PATH_S3_BUCKET}/${featured_image_path}`}
                  alt="featured_image_path"
                  style={{
                    marginBottom: "7px",
                    width: "100%",
                    height: "100%",
                    maxWidth: "250px",
                    maxHeight: "250px",
                  }}
                />
              ) : (
                <img
                  src={`https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/330px-No-Image-Placeholder.svg.png?20200912122019`}
                  alt="featured_image_path_not"
                  style={{
                    marginBottom: "7px",
                    width: "100%",
                    height: "100%",
                    maxWidth: "250px",
                    maxHeight: "250px",
                  }}
                />
              )}
              <Upload
                filePath="featured_image_path"
                onUploadedFile={(files) => {
                  // console.log({ files });
                  const file = files[0];
                  setValue("featured_image_path", file.fileKey);
                  message.success("Se ha actualizado la imagen");
                }}
              />
            </Col>
          )}
          <Col
            span={12}
            style={{
              display: "flex",
              marginTop: "10px",
              padding: 5,
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              Género:
            </Title>
            <InputSelect
              name="gender"
              control={control}
              dataOptions={[
                { title: "Femenino", value: "F" },
                { title: "Masculino", value: "M" },
              ]}
              label="Categoría principal"
            />
          </Col>
          <Col
            span={12}
            style={{
              display: "flex",
              marginTop: "10px",
              padding: 5,
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              Nombre:
            </Title>
            <InputText
              placeHolder="John Doe"
              name="name"
              control={control}
            />
          </Col>
          <Col
            span={12}
            style={{
              display: "flex",
              marginTop: "10px",
              padding: 5,
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Title style={{ marginBottom: "1.5px" }} level={5}>
                Razón de la consulta
            </Title>
            <InputText
              placeHolder="Chequeo general..."
              name="reason_for_consultation"
              control={control}
            />
          </Col>
          {isUpdateClinicalCase && (
            <Col
              span={12}
              style={{
                display: "flex",
                marginTop: "10px",
                padding: 5,
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <Title style={{ marginBottom: "1.5px" }} level={5}>
                Estado:
              </Title>
              <InputSelect
                name="status"
                control={control}
                dataOptions={[
                  { title: "Activo", value: "active" },
                  { title: "Inactivo", value: "inactive" },
                ]}
                label="Estado"
              />
            </Col>
          )}
          <Col
            span={24}
            style={{
              display: "flex",
              marginTop: "10px",
              padding: 5,
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              Cuerpo del mensaje:
            </Title>
            {isUpdateClinicalCase ? (
              <InputHTML
                placeHolder="Es un paciente muy optimo para..."
                name="html_body"
                control={control}
                isUpdating
              />
            ) : (
              <InputHTML
                placeHolder="Es un paciente muy optimo para..."
                name="html_body"
                control={control}
              />
            )}
          </Col>
            <Col
              span={24}
              style={{
                display: "flex",
                marginTop: "10px",
                padding: 5,
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <Title style={{ marginBottom: "1.5px" }} level={5}>
                ID del formulario:
              </Title>
              <InputText
              placeHolder="2dcfsa2234sf"
              name="id_form"
              control={control}
            />
            </Col>
          <Col
            span={24}
            style={{
              display: "flex",
              marginTop: "10px",
              justifyContent: "center",
              alignItems: "flex-end",
              flexDirection: "column",
            }}
          >
            <Button
              style={{
                marginTop: 10,
              }}
              type="primary"
              shape="round"
              size="large"
              onClick={onSubmit(onSubmitCreateOrUpdate)}
              loading={isLoading}
            >
              {isUpdateClinicalCase ? "Actualizar información" : "Crear caso clínico"}
            </Button>
          </Col>
        </Row>
    )}
    </>
  )
}
