import { yupResolver } from "@hookform/resolvers/yup";
import { message } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { validateCreateEvent, validateUpdateEvent } from "../../../helpers";

import {
  createEvent,
  refreshTableEvent,
  selectEventForUpdate,
  selectEventRefreshTable,
  selectIsUpdateEvent,
  setIsEventForUpdate,
  updateEvent,
} from "../../../redux";
import { IEventSingle } from "../../../redux/@types";
import useAppDispatch from "../../../redux/hooks/useAppDispatch";
import { TableProperties } from "../../../shared/components/ui/Table/types";

export const useEvents = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const locationPath = useLocation();
  const isUpdateEvent = useSelector(selectIsUpdateEvent);
  const dataUpdateEvent = useSelector(selectEventForUpdate);
  const isRefreshTableEvents = useSelector(selectEventRefreshTable);

  const [isLoading, setIsLoading] = useState(false);
  const [tableProps, setTableProps] = useState<TableProperties>();

  const navigateEventCreate = () => {
    dispatch(setIsEventForUpdate(false));
    navigate(`/events/${locationPath.pathname.split("/")[2]}/new`);
  };
  const formMethodsCreate = useForm<IEventSingle>({
    resolver: yupResolver(validateCreateEvent),
  });

  const formMethodsUpdate = useForm<IEventSingle>({
    resolver: yupResolver(validateUpdateEvent),
  });

  useEffect(() => {
    if (isUpdateEvent) {
      formMethodsCreate.reset();
      formMethodsUpdate.reset({ ...dataUpdateEvent });
    }

    return () => {
      formMethodsUpdate.reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateEvent, dataUpdateEvent]);

  const updateStatus = async (value: "active" | "inactive", id: any) => {
    if (isLoading) return;
    setIsLoading(true);
    await updateEvent({
      id,
      status: value,
    })
      .then((res: Partial<IEventSingle>) => {
        setIsLoading(false);
        if (res.id) {
          // dispatch(bannersChangeModalState());
          message.success(
            `Se ha actualizado con éxito el estado del producto con id:${res.id}`
          );
          dispatch(refreshTableEvent(true));
        } else {
          message.error(res as string);
        }
      })
      .catch((err: any) => {
        setIsLoading(false);
        console.log(err);
        message.error(err.message);
      });
  };

  const onSubmitCreateOrUpdate = async (data: IEventSingle) => {
    // console.log({ data });
    if (isLoading) return;
    setIsLoading(true);
    if (isUpdateEvent) {
      // console.log("Actualiando===>", data);
      await updateEvent({
        id: data.id,
        title: data.title,
        // slug: data.slug,
        start_date: moment(data.start_date).utcOffset(0).format("YYYY-MM-DD HH:mm:ss"),
        end_date: moment(data.end_date).utcOffset(0).format("YYYY-MM-DD HH:mm:ss"),
        html_body: data.html_body,
        featured_image_path: data.featured_image_path,
        cover_image_path: data.cover_image_path,
        scp_type: data.scp_type ? data.scp_type : null,
        web_url: data.web_url,
        html_short_description: data.html_short_description,
        event_location: data.event_location,
        type: locationPath.pathname.split("/")[2] as string,
        status: data.status,
        pdf_programa: data.pdf_programa,
      })
        .then((res: Partial<IEventSingle>) => {
          setIsLoading(false);
          // dispatch(mappingUpdatedUser(res))
          if (res.id) {
            // navigate(`/event/${locationPath.pathname.split("/")[2]}`);
            message.success(
              `Se ha actualizado con éxito el evento con id:${res.id}`
            );
            dispatch(refreshTableEvent(true));
          } else {
            message.error(res as string);
          }
        })
        .catch((err: any) => {
          setIsLoading(false);
          console.log(err);
          message.error(err.message);
        });
    } else {
      // console.log('Creando===>',data)
      // setIsLoading(false);
      await createEvent({
        title: data.title,
        // slug: data.slug,
        start_date: moment(data.start_date).utcOffset(0).format("YYYY-MM-DD HH:mm:ss"),
        end_date: moment(data.end_date).utcOffset(0).format("YYYY-MM-DD HH:mm:ss"),
        html_body: data.html_body,
        featured_image_path: data.featured_image_path,
        cover_image_path: data.cover_image_path,
        scp_type: data.scp_type ? data.scp_type : null,
        web_url: data.web_url,
        html_short_description: data.html_short_description,
        event_location: data.event_location,
        type: locationPath.pathname.split("/")[2] as string,
        status: "inactive",
      })
        .then((res: any) => {
          setIsLoading(false);
          if (res.id) {
            // dispatch(productsChangeModalState());
            navigate(`/events/${locationPath.pathname.split("/")[2]}`);
            message.success(
              `Se ha creado con éxito el evento con id:${res.id}`
            );
            dispatch(refreshTableEvent(true));
          } else {
            message.error(res as any);
          }
        })
        .catch((err: any) => {
          setIsLoading(false);
          console.log(err);
          message.error(err.message);
        });
    }
  };

  useEffect(() => {
    if (isRefreshTableEvents) {
      tableProps?.refresh();
      dispatch(refreshTableEvent(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefreshTableEvents]);
  return {
    //state
    isUpdateEvent,
    isLoading,
    tableProps,
    locationPath,
    //methods
    formMethodsCreate,
    formMethodsUpdate,
    setTableProps,
    //functions
    navigateEventCreate,
    onSubmitCreateOrUpdate,
    updateStatus,
  };
};
