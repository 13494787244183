import { ReactNode } from "react";
import { MenuProps } from "antd";
import {
  NumberOutlined,
  UserOutlined,
  FileSyncOutlined,
  SolutionOutlined,
  AuditOutlined,
  CalendarOutlined,
  ShoppingOutlined,
  AimOutlined,
  TagsOutlined,
  StarOutlined,
  HighlightOutlined,
  ProfileOutlined,
  FileSearchOutlined,
  PercentageOutlined,
  HomeOutlined,
  EnvironmentOutlined,
  ShopOutlined,
  ShoppingCartOutlined,
  DollarCircleOutlined,
  ReconciliationOutlined,
  SnippetsOutlined,
  WalletOutlined,
  TagOutlined,
  ScheduleOutlined,
  FileDoneOutlined,
  FileZipOutlined,
  RocketOutlined,
  FileProtectOutlined,
  StockOutlined,
} from "@ant-design/icons";
import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import { useSelector } from "react-redux";
import { selectCollapsed, uiChangeCollapsedState } from "../../../../redux";
import { Link, useLocation } from "react-router-dom";

type MenuItem = Required<MenuProps>["items"][number];

export const useAdminMenuLayout = () => {
  const dispatch = useAppDispatch();
  const collapsed = useSelector(selectCollapsed);
  let { pathname } = useLocation();

  function getItem(
    icon: ReactNode,
    label: ReactNode,
    key: string,
    children?: MenuItem[]
  ): MenuItem {
    return {
      icon,
      label,
      key,
      children,
    } as MenuItem;
  }

  let items: MenuItem[] = [
    getItem(<UserOutlined />, <Link to={"/users"}>Users</Link>, "/users"),
    getItem(<NumberOutlined />, <>Residentes</>, "/residents", [
      // getItem(
      //   <SolutionOutlined />,
      //   <Link to={"/clinicalCases/RESIDENTES"}>Casos clínicos</Link>,
      //   "/clinicalCases/RESIDENTES"
      // ),
      getItem(
        <CalendarOutlined />,
        <Link to={"/events/residents"}>Eventos</Link>,
        "/events/residents"
      ),
      // getItem(
      //   <RocketOutlined />,
      //   <Link to={"/ninjaFormsWebhook/residents"}>Ninja form web hook</Link>,
      //   "/ninjaFormsWebhook/residents"
      // ),
    ]),
    getItem(<NumberOutlined />, <>SCP</>, "/scp", [
      // getItem(
      //   <SolutionOutlined />,
      //   <Link to={"/clinicalCases/SCP"}>Casos clínicos</Link>,
      //   "/clinicalCases/SCP"
      // ),
      getItem(
        <CalendarOutlined />,
        <Link to={"/events/scp"}>Eventos</Link>,
        "/events/scp"
      ),
      getItem(
        <FileSyncOutlined />,
        <Link to={"/leads"}>Contactos</Link>,
        "/leads"
      ),
      getItem(
        <AuditOutlined />,
        <Link to={"/requestsOfDataUpdate"}>Sol. actua. datos</Link>,
        "/requestsOfDataUpdate"
      ),
      // getItem(
      //   <RocketOutlined />,
      //   <Link to={"/ninjaFormsWebhook/scp"}>Ninja form web hook</Link>,
      //   "/ninjaFormsWebhook/scp"
      // ),
    ]),
  ];

  const changeCollapsed = () => {
    dispatch(uiChangeCollapsedState());
  };
  return {
    //state
    collapsed,
    items,
    pathname,
    //methods
    //functions
    changeCollapsed,
  };
};
